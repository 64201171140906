const init = { 
    isUpdating: false 
};

const Soc1 = (state = init, action) => {
    switch (action.type) {
        case 'SOC1_IS_UPDATING':
            return { isUpdating: action.payload }
        default: 
            return state;
    }
}

export default Soc1;