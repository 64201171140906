import React, { Component } from "react";
import axios from "axios";
import Select from "antd/es/select";
import { connect } from "react-redux";
import {
  SET_CLOUD_ACCOUNT_ID,
  SET_CLOUD_TYPE,
  SET_CLOUD_ACTION
} from "constants/ActionTypes";
import { printMsg } from "util/functions";
import { standardCallStorage } from "util/callStorage/standardCallStorage";
import AWSLogo from "assets/images/aws_logo.svg";
import AzureLogo from "assets/images/azure_logo_min.png";
import baseOC from "icfrontendbase";
import "styles/pages/topbar.css";

const sharedStorage = baseOC.get("SharedStorage");
const Option = Select.Option;

class CloudAccountSelector extends Component {
  constructor() {
    super();
    this.state = {
      cloudAccountList: [],
      loading: true
    }
  }

  componentDidMount = async () => {
    this.setState({ loading: true });

    // persist redux state with sessionStorage
    if (this.props.cloudAccountId === null && sessionStorage.getItem("cloudAccountId")) {
      this.props.setCloudAccountId(
        sessionStorage.getItem("cloudAccountId")
      );
    }
    if (this.props.cloudType === null && sessionStorage.getItem("cloudType")) {
      this.props.setCloudType(
        sessionStorage.getItem("cloudType")
      );
    }

    try {
      // Get cloud accounts
      const cloudAccountsRes = await axios.get("/backend/account/cloudAccounts");
      const cloudAccountList = cloudAccountsRes.data;
      this.setState({ cloudAccountList: cloudAccountList });

      // sometimes shared storage goes out of sync with the cloud already selected. like the shared storage cloud is undefined after reloading the page
      // this is a half measure to fix it for now, it should not be copied into a product component later. none of this code should be copied really
      sharedStorage.subscribe("cac_in_focus_cloud_type", (ssCloudType) => {
        if (typeof ssCloudType === "undefined" && this.props.cloudAccountId) {
          const newSSCloudDetails = this.getSelectedCloudDetails(cloudAccountList, this.props.cloudAccountId);
          this.updateSharedStorage({
            cloud_name: newSSCloudDetails.cloudName,
            cloud_account_id: this.props.cloudAccountId,
            cloud_type: newSSCloudDetails.cloudType
          })
        }
      })

      // If cloudAccountId is already set, dont set a new one
      if (this.props.cloudAccountId) {
        this.setState({ loading: false });
        return;
      }

      // Set the selected cloud to the first one, since none is set yet
      let selectedCloudAccount = null;
      let selectedCloudType = null;
      let selectedCloudName = "";
      if (cloudAccountList.length > 0) {
        selectedCloudAccount = cloudAccountList[0].cloud_id;
        selectedCloudType = cloudAccountList[0].cloud_type;
        selectedCloudName = cloudAccountList[0].cloud_name;
      }
      this.props.setCloudAccountId(selectedCloudAccount);
      this.props.setCloudType(selectedCloudType);
      sessionStorage.setItem("cloudAccountId", selectedCloudAccount);
      sessionStorage.setItem("cloudType", selectedCloudType);
      this.updateSharedStorage({
        cloud_name: selectedCloudName,
        cloud_account_id: selectedCloudAccount,
        cloud_type: selectedCloudType
      })

    } catch(error) {
      printMsg("error", "Error retreiving Cloud Account data, please try again soon", 10);
    }
    this.setState({ loading: false });
  }

  handleSelectChange = async (cloudAccountId) => {
    const { cloudAccountList } = this.state;

    this.removeCloudAccountCache();

    // get the awsAccountNumber, if it exists
    let awsAccountNumber = null;
    cloudAccountList.forEach(cloudAccount => {
      if (cloudAccount.cloud_id === cloudAccountId) {
        awsAccountNumber = cloudAccount.aws_account_number;
      }
    });

    // update selected cloud account    
    try {
      await axios.post(
        "/backend/constant/cloudAccountId",
        { 
          cloud_account_id: cloudAccountId, 
          account_number: awsAccountNumber 
        }
      )
    } catch (error) {
      printMsg("error", "Error selecting Cloud Account, please try again soon", 10);
      return
    }
    const { cloudType, cloudName } = this.getSelectedCloudDetails(cloudAccountList, cloudAccountId);
    this.props.setCloudAccountId(cloudAccountId);
    this.props.setCloudType(cloudType);
    sessionStorage.setItem("cloudAccountId", cloudAccountId);
    sessionStorage.setItem("cloudType", cloudType);
    this.updateSharedStorage({
      cloud_name: cloudName,
      cloud_account_id: cloudAccountId,
      cloud_type: cloudType
    }) 

    // FUTURE NOTE: We should reload whatever component is shown, instead of the whole page
    window.location.reload(false);
  }

  getSelectedCloudDetails = (cloudAccountList, cloudAccountId) => {
    let cloudType = null;
    let cloudName = "";
    const selectedCloudAccount = cloudAccountList.filter(cloudAccount => cloudAccount.cloud_id === cloudAccountId);
    if (selectedCloudAccount[0] && selectedCloudAccount[0].cloud_type) {
      cloudType = selectedCloudAccount[0].cloud_type;
      cloudName = selectedCloudAccount[0].cloud_name;
    }
    return { cloudType, cloudName };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (sessionStorage.getItem("cloudAccountId") === null) return;

    if (this.props.cloudAccountId !== newProps.cloudAccountId && newProps.cloudAccountId !== null) {
      // if the cloud account id is a new one
      const cloudAccountMatch = this.state.cloudAccountList.filter(cloudAccount => (
        cloudAccount.cloud_id === newProps.cloudAccountId
      ));
      if (
        cloudAccountMatch.length === 0 && 
        window.location.pathname.includes("/account/cloudaccountsetup") 
      ) {
        this.removeCloudAccountCache();
        this.handleAddCloudAcccount(newProps.cloudAccountId);
      }
    }

    if (this.props.cloudAction !== newProps.cloudAction && newProps.cloudAction !== null) {
      // if any cloud account is edited, then reload the component
      if (newProps.cloudAction === "reload") {

      }
      // if the selected cloud account is removed, then reset the component
      if (newProps.cloudAction === "reset") {
        sessionStorage.removeItem("cloudAccountId");
        sessionStorage.removeItem("cloudType");
        this.props.setCloudAccountId(null);
        this.props.setCloudType(null);   
        this.updateSharedStorage({
          cloud_name: "",
          cloud_account_id: null,
          cloud_type: null
        })    
      }
      this.componentDidMount();
      this.props.setCloudAction(null);
    }
  }

  removeCloudAccountCache = () => {
    standardCallStorage.removeValue("/backend/data/assessmentMeta");
  }

  // when user adds a cloud account, we want to switch to that cloud account
  // dont want to refresh page in this case
  handleAddCloudAcccount = async addedCloudAccountId => {
    try {
      const cloudAccountRes = await axios.get("/backend/account/cloudAccounts");
      const cloudAccountList = cloudAccountRes.data;

      const selectedCloudAccount = cloudAccountList.filter(cloudAccount => cloudAccount.cloud_id === addedCloudAccountId);
      let aws_account_number = null;
      if (selectedCloudAccount[0] && selectedCloudAccount[0].aws_account_number) {
        aws_account_number = selectedCloudAccount[0].aws_account_number;
      }
      axios.post(
        "/backend/constant/cloudAccountId",
        {
          cloud_account_id: addedCloudAccountId,
          account_number: aws_account_number
        }
      );

      this.setState({
        cloudAccountList: cloudAccountList
      });

      sessionStorage.setItem("cloudAccountId", addedCloudAccountId);

      const { cloudType, cloudName } = this.getSelectedCloudDetails(cloudAccountList, addedCloudAccountId);
      this.props.setCloudType(cloudType);
      sessionStorage.setItem("cloudType", cloudType);
      this.updateSharedStorage({
        cloud_name: cloudName,
        cloud_account_id: addedCloudAccountId,
        cloud_type: cloudType
      })

    } catch (error) {

    }
  }

  // this is temporary until we have a cloud selector product component 
  updateSharedStorage = ({ cloud_name, cloud_account_id, cloud_type }) => {
    sharedStorage.set("cac_in_focus_cloud_display_name", cloud_name);
    sharedStorage.set("cac_in_focus_cloud_account_id", cloud_account_id);
    sharedStorage.set("cac_in_focus_cloud_type", cloud_type);
  }

  render() {
    const { cloudAccountList, loading } = this.state;
    const { cloudAccountId } = this.props;

    if (loading) {
      return (
        <div
          style={{ width: this.props.width ? "75%" : "100%" }}
          className="ant-row-flex no-wrap"
        >
          <Select
            className="cloud-account-select"
            placeholder="Loading..."
            loading={loading}
          />
        </div>
      )
    }

    if (cloudAccountList && cloudAccountList.length === 0) {
      return null;
    }

    return (
      <div
        style={{ width: this.props.width ? "75%" : "100%" }}
        className="ant-row-flex no-wrap"
      >
        <Select
          className="cloud-account-select"
          placeholder="Select Cloud Account"
          onChange={this.handleSelectChange}
          value={cloudAccountId}
          dropdownStyle={{ minWidth: "fit-content" }}
        >
          {cloudAccountList.map((account, index) => (
            <Option value={account.cloud_id} key={index}>
              <div style={{ display: "flex" }}>
                {account?.cloud_type === "aws" ? (
                  <>
                    <div className="cloud-account-img-container">
                      <img
                        src={AWSLogo}
                        alt="AWS Logo"
                        className="mt-[11px]"
                      />
                    </div>
                    {account.cloud_name} - {account.aws_account_number}
                  </>
                ) : account?.cloud_type === "azure" ? (
                  <>
                    <div className="cloud-account-img-container">
                      <img
                        src={AzureLogo}
                        alt="Azure Logo"
                        className="mt-[10px]"
                      />
                    </div>
                    {account.cloud_name}
                  </>
                ) : null}
              </div>
            </Option>
          ))}
        </Select>

        <div
          className="d-flex align-items-end gx-mb-1 gx-ml-2"
          style={{ textAlign: "center" }}
        >
          <h2
            className={window.innerWidth < "400" ? "gx-fs-xl  gx-mr-2 gx-mb-0" : "gx-fs-xxxl  gx-mr-2 gx-mb-0"}
            style={{ verticalAlign: "middle" }}
          >
            {cloudAccountList ? cloudAccountList.length : null}
          </h2>
          <span className="gx-mr-3 gx-mb-2">
            {window.innerWidth < "400" ? (
              <i
                className="fa fa-cloud"
                style={{
                  color: "dodgerblue",
                  height: 7,
                  width: 8.5,
                  verticalAlign: "bottom",
                  fontSize: "12px"
                }}
              />
            ) : (
              <i
                className="fa fa-cloud"
                style={{
                  color: "dodgerblue",
                  height: 15,
                  width: 16.5,
                  verticalAlign: "bottom",
                  fontSize: "22px"
                }}
              />
            )}
          </span>
          <h4
            className=" gx-mb-0 gx-chart-up gx-text-grey"
            id="topbar-accounts-monitored"
            style={{ verticalAlign: "middle" }}
          >
            {" Account(s) Monitored"}
          </h4>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ cloudAccountId, cloudType, cloudAction }) => {
  return { cloudAccountId, cloudType, cloudAction };
};

const mapDispatchToProps = dispatch => {
  return {
    setCloudAccountId: data => dispatch({ type: SET_CLOUD_ACCOUNT_ID, payload: data }),
    setCloudType: data => dispatch({ type: SET_CLOUD_TYPE, payload: data }),
    setCloudAction: data => dispatch({ type: SET_CLOUD_ACTION, payload: data })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CloudAccountSelector);
