import React, { Component } from "react";
import Menu from "antd/es/menu";
import Icon from "antd/es/icon";
import { NavLink, withRouter } from "react-router-dom";
import axios from "axios";

import "../../styles/layout/sidebar.css";
import SidebarLogo from "./SidebarLogo";
import Auxiliary from "util/Auxiliary";
import {
  TAB_SIZE,
  NAV_STYLE,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { connect } from "react-redux";
import UserProfile from "./UserProfile";
import TierInfo from "./TierInfo";
import { getWhiteLabelSupportMeetingLink } from "util/whitelabel";
import { getSubscribedAddonsLegacyContract } from "util/functions/getSubscribedAddons";
import CisGreyImage from "assets/images/CIS.jpg";
import CisImage from "assets/images/CIS.jpg";
import AwsWaImage from "assets/images/AWS_WA_ICON_2_BLUE.png";
import AwsWaGreyImage from "assets/images/AWS_WA_ICON_2_BLUE.png";

const { SubMenu } = Menu;

const getComplianceMenuItems = (addons, cloudType, pathname) => {
  const menuItems = [];
  
  // Define all possible compliance modules (excluding security items)
  const complianceModules = [
    {
      id: "soc2",
      path: "portal/compliance/soc2",
      messageId: "sidebar.portal.compliance.soc2"
    },
    {
      id: "hipaa",
      path: "portal/compliance/hipaa",
      messageId: "sidebar.compliance.hipaa"
    },
    {
      id: "iso27001",
      path: "portal/compliance/iso27001",
      messageId: "sidebar.compliance.iso27001"
    },
    // {
    //   id: "soc1",
    //   path: "portal/compliance/soc1",
    //   messageId: "sidebar.portal.compliance.soc1"
    // },
    {
      id: "nist171",
      path: "portal/compliance/nist171",
      messageId: "sidebar.compliance.nist171"
    },
    {
      id: "nist853",
      path: "portal/compliance/nist853",
      messageId: "sidebar.compliance.nist853"
    }
  ];

  // Filter and create menu items based on purchased addons and cloud type
  complianceModules.forEach(module => {
    if (addons && addons.includes(module.id) && !(cloudType === "azure" && module.id.startsWith("nist"))) {
      menuItems.push(
        <Menu.Item key={module.path}>
          <NavLink to={`/${module.path}`} className="padding-left-sidebar">
            <span className="submenu-unlocked-link-color">
              <IntlMessages id={module.messageId} />
            </span>
          </NavLink>
        </Menu.Item>
      );
    }
  });

  return menuItems;
};

// Add this helper function to get security menu items
const getSecurityMenuItems = (addons, cloudType) => {
  const menuItems = [];
  
  if (cloudType === "aws") {
    // Security modules specific to AWS
    const securityModules = [
      {
        id: "cislevel1",
        path: "portal/compliance/cislevel1",
        messageId: "sidebar.compliance.cislevel1"
      },
      {
        id: "cislevel2",
        path: "portal/compliance/cislevel2",
        messageId: "sidebar.compliance.cislevel2"
      },
      {
        id: "awswellarch",
        path: "portal/compliance/awswellarch",
        messageId: "sidebar.compliance.awswellarch"
      }
    ];

    securityModules.forEach(module => {
      if (addons && (addons.includes(module.id) || 
          (module.id === "cislevel1" && addons.includes("cis")) || 
          (module.id === "cislevel2" && addons.includes("cis")))) {
        menuItems.push(
          <Menu.Item key={module.path}>
            <NavLink to={`/${module.path}`} className="padding-left-sidebar">
              <span className="submenu-unlocked-link-color">
                <IntlMessages id={module.messageId} />
              </span>
            </NavLink>
          </Menu.Item>
        );
      }
    });
  } else {
    // For non-AWS clouds, show security summary
    // menuItems.push(
    //   <Menu.Item key="portal/compliance/security">
    //     <NavLink to="/portal/compliance/security" className="padding-left-sidebar">
    //       <span className="submenu-unlocked-link-color">
    //         <IntlMessages id="Summary" />
    //       </span>
    //     </NavLink>
    //   </Menu.Item>
    // );
  }

  return menuItems;
};

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addons: null,
      plan_id: null,
      user_type: null,
      navStyle: null,
      addonDataLoaded: false,
      awsMargin: "0px 0px 0px 5px",
      defaultOpenKeys: ["compliance"],
      pentestMargin: "0px 0px 0px -52px",
      awsClass: "",
      pentestClass: "",
      lockedAwsClass: "",
      lockedAwsMargin: "",
      lockedPentestClass: "",
      lockedPentestMargin: "",
      openKeys: props.defaultOpenKeys || []
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.settings?.menuState?.openKeys !== prevProps.settings?.menuState?.openKeys) {
      this.setState({ openKeys: this.props.settings.menuState.openKeys });
    }
  }

  getNoHeaderClass = navStyle => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  getNavStyleSubMenuClass = navStyle => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  handleLinkColor = (policy, policyId) => {
    if (policy === "complianceManagement") {
      if (
        (this.state.addons && this.state.addons.includes("soc2")) ||
        (this.state.addons && this.state.addons.includes("iso27001")) ||
        (this.state.addons && this.state.addons.includes("soc1"))
      ) {
        return (
          <span className="submenu-unlocked-link-color">
            <IntlMessages id={policyId} />
          </span>
        );
      } else {
        return (
          <span className="locked-link-color">
            <IntlMessages id={policyId} />
          </span>
        );
      }
    } else {
      if (this.state.addons && this.state.addons.includes(policy)) {
        return (
          <span className="submenu-unlocked-link-color">
            <IntlMessages id={policyId} />
          </span>
        );
      } else {
        return (
          <span className="locked-link-color">
            <IntlMessages id={policyId} />
          </span>
        );
      }
    }
  };

  handleLinkColorWithImage = (policyId, icon) => {
    return (
      <span className="flex-align-center-sidebar">
        <Icon type={icon} style={{ fontSize: "20px", color: "DodgerBlue" }} />
        <span
          className="unlocked-link-color"
          style={{ color: this.handleUnlockedLinkColor() }}
        >
          <IntlMessages id={policyId} />
        </span>
      </span>
    );
  };

  handleChangingLinkColorWithImage = (
    policy,
    policyId,
    greyImage,
    blueImage,
    alt
  ) => {
    if (this.state.addons && !this.state.addons.includes(policy)) {
      return (
        <span className="flex-align-center-sidebar locked-link-color">
          <Icon
            component={() => (
              <img
                alt={alt}
                style={{
                  maxWidth: "25px",
                  height: "25px",
                  margin: "0 -2px 0 -3px"
                }}
                src={greyImage}
              />
            )}
          />
          <IntlMessages id={policyId} />
        </span>
      );
    } else {
      return (
        <span className="flex-align-center-sidebar">
          <Icon
            component={() => (
              <img
                alt={alt}
                style={{
                  maxWidth: "25px",
                  height: "25px",
                  margin: "0 -2px 0 -3px"
                }}
                src={blueImage}
              />
            )}
          />
          <span
            className="unlocked-link-color"
            style={{ color: this.handleUnlockedLinkColor() }}
          >
            <IntlMessages id={policyId} />
          </span>
        </span>
      );
    }
  };

  OpenKeysCheck = data => {
    if (this.state.openKeys.length === 0) {
      let openKeys = [];

      if (data === "NAV_STYLE_MINI_SIDEBAR") {
        this.setState({ openKeys: [] });
      } else {
        if (window.location.pathname.includes('/portal/compliance/security/')) {
          openKeys.push("security");
        } else if (window.location.pathname.includes('/portal/compliance/')) {
          openKeys.push("compliance");

          if (window.location.pathname.includes("/devicemanagement/")) {
            openKeys.push("deviceManagementSubMenu");
          }
        } else if (window.location.pathname.includes('/portal/pentest/')) {
          openKeys.push("compliance");
        } else if (window.location.pathname.includes("account/usersettings")) {
          openKeys.push("account");
          openKeys.push("account/usersettings");
        } else if (window.location.pathname.includes("account")) {
          openKeys.push("account");
        }
      }

      this.setState({ openKeys });
    }
  };

  async componentDidMount() {
    this.OpenKeysCheck(this.props.navStyle);
    let addons = null;
    let plan_id = null;
    await getSubscribedAddonsLegacyContract().then(res => {
      if (res) {
        if (typeof res.data === "string") {
          addons = res.data.split(",");

          if (addons && typeof addons !== "undefined" && addons.length > 0) {
            addons.forEach((addon, index) => {
              if (addon === "cis") {
                addons[index] = "cislevel1";
                addons = addons.concat("cislevel2");
              }
            });
          }
        }
        this.setState({
          navStyle: this.props.navStyle,
          addons: addons,
          addonDataLoaded: true
        });
      }
    });

    await axios.get("/backend/constant/getPlanId").then(res => {
      plan_id = res.data;
      this.setState({ plan_id: plan_id });
      if (plan_id === "free") {
        this.setState({ addons: "cislevel1,cislevel2" });
      }
    });

    await axios.get("/backend/constant/getUserType").then(res => {
      var user_type = res.data;
      this.setState({ user_type: user_type });
    });
    try {
      if (!addons.includes("awswellarch")) {
        this.setState({ lockedAwsClass: "locked-icon-sidebar-text" });
      }
      if (!addons.includes("pentest")) {
        this.setState({ lockedPentestClass: "locked-icon-sidebar-text" });
      }
      if (plan_id.includes("free")) {
        this.setState({ lockedQuestionnaireClass: "locked-icon-sidebar-text" });
      }
    } catch {
      this.setState({
        lockedAwsClass: "locked-icon-sidebar-text",
        lockedPentestClass: "locked-icon-sidebar-text",
        lockedQuestionnaireClass: "locked-icon-sidebar-text",
        firstRender: false
      });
    }

    // Check if we're on a security path and set openKeys accordingly
    if (window.location.pathname.includes('/portal/compliance/security/')) {
      this.setState(prevState => ({
        openKeys: [...prevState.openKeys, 'security']
      }));
    }
  }
  handleUnlockedLinkColor = () => {
    if (this.props.navOpen === false && this.props.windowWidth > TAB_SIZE) {
      return "white";
    } else {
      return "#232323";
    }
  };
  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({ navStyle: newProps.navStyle });
    if (newProps.navStyle !== this.props.navStyle) {
      this.OpenKeysCheck(newProps.navStyle);
    }
  }
  onOpenChange = (openKeys) => {
    // Get the last key that was clicked
    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);

    // If security is clicked while compliance is open (or vice versa), keep both open
    let nextOpenKeys = openKeys;
    if (latestOpenKey === 'security' && this.state.openKeys.includes('compliance')) {
      nextOpenKeys = [...new Set([...this.state.openKeys, 'security'])];
    } else if (latestOpenKey === 'compliance' && this.state.openKeys.includes('security')) {
      nextOpenKeys = [...new Set([...this.state.openKeys, 'compliance'])];
    }

    this.setState({ openKeys: nextOpenKeys });

    // When compliance menu is opened, navigate to summary without page refresh
    if (nextOpenKeys.includes('compliance') && !this.props.pathname.includes('/compliance/')) {
      this.props.history.push('/portal/compliance/summary');
    }
  };

  // Add this method to check if we're in a compliance path
  isCompliancePath = (pathname) => {
    const compliancePaths = [
      '/portal/compliance/soc2',
      '/portal/compliance/hipaa',
      '/portal/compliance/iso27001',
      '/portal/compliance/nist171',
      '/portal/compliance/nist853',
      '/portal/compliance/cislevel1',
      '/portal/compliance/cislevel2',
      '/portal/compliance/soc1',
      '/portal/compliance/awswellarch',
      '/portal/compliance/compliancemanagement'
    ];
    return compliancePaths.some(path => pathname.includes(path));
  };

  render() {
    const { themeType, pathname, navStyle, cloudType } = this.props;
    const {
      addonDataLoaded,
      addons,
      openKeys,
      plan_id,
      user_type
    } = this.state;

    const selectedKeys = pathname.substr(1);
    return (
      <Auxiliary>
        <SidebarLogo windowWidth={this.props.windowWidth} />
        <div className="gx-sidebar-content font-poppins">
          <div className="sidebar">
            {addonDataLoaded === false ? null : (
              <div style={{ overflow: "auto", overflowX: "hidden" }}>
                {/* <div
                  className={`gx-sidebar-notifications  ${this.getNoHeaderClass(
                    navStyle
                  )}`}
                > */}
                <div style={{ marginTop: "10px" }}></div>
                {/* <UserProfile /> */}
                {/* <div style={{ marginTop: "-40px" }}>
                    <TierInfo navStyle={navStyle} />
                  </div> */}
                {/* </div> */}
                <div style={{ marginBottom: "150px" }}>
                  <div className="gx-layout-sider-scrollbar">
                    <Menu
                      onOpenChange={this.onOpenChange}
                      triggerSubMenuAction={
                        window.innerWidth > TAB_SIZE &&
                        navStyle === "NAV_STYLE_MINI_SIDEBAR"
                          ? "hover"
                          : "click"
                      }
                      openKeys={openKeys}
                      defaultSelectedKeys={[selectedKeys]}
                      selectedKeys={[selectedKeys]}
                      theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                      mode={"inline"}
                    >
                      <Menu.Item key="portal/dashboard">
                        <NavLink
                          to="/portal/dashboard"
                          activestyle={{
                            borderBottom: "solid 3px #fff",
                            paddingBottom: "1em"
                          }}
                        >
                          <span className="flex-align-center-sidebar">
                            <Icon
                              type="bar-chart"
                              style={{ fontSize: "20px", color: "DodgerBlue" }}
                            />
                            <span
                              className="unlocked-link-color"
                              style={{ color: this.handleUnlockedLinkColor() }}
                            >
                              <IntlMessages id="sidebar.summary.overall.summary" />
                            </span>
                          </span>
                        </NavLink>
                      </Menu.Item>
                      <SubMenu
                        key="compliance"
                        className={this.getNavStyleSubMenuClass(navStyle)}
                        onTitleClick={() => {
                          this.props.history.push('/portal/compliance/summary');
                        }}
                        title={
                          <span>
                            <Icon
                              type="safety"
                              style={{
                                fontSize: "20px",
                                color: "DodgerBlue",
                                marginTop: ".5em",
                                marginRight: "1em"
                              }}
                            />
                            <span className={`submenu-unlocked-link-color ${openKeys.includes('compliance') ? 'active-main-title' : ''}`}>
                              <IntlMessages id="sidebar.compliance" />
                            </span>
                          </span>
                        }
                      >
                        {getComplianceMenuItems(addons, cloudType, pathname)}
                        
                        {this.isCompliancePath(pathname) && (
                          <SubMenu
                            key="complianceManagement"
                            className={`${this.getNavStyleSubMenuClass(navStyle)} padding-left-sidebar-menu`}
                            title={
                              (addons && addons.includes("soc2")) ||
                                (addons && addons.includes("iso27001")) ||
                                (addons && addons.includes("soc1")) ? (
                                <span>
                                  <span
                                    style={{
                                      marginLeft: "10px",
                                      position: "relative",
                                      bottom: "2px",
                                      right: "13px"
                                    }}
                                  >
                                    {" "}
                                    <span className="submenu-unlocked-link-color">
                                      <IntlMessages id="sidebar.portal.compliance.complianceManagement" />{" "}
                                    </span>
                                  </span>
                                </span>
                              ) : (
                                <span className="locked-link-color">
                                  <span
                                    style={{
                                      marginLeft: "10px",
                                      position: "relative",
                                      bottom: "2px",
                                      right: "13px"
                                    }}
                                  >
                                    {" "}
                                    <IntlMessages id="sidebar.portal.compliance.complianceManagement" />{" "}
                                  </span>
                                </span>
                              )
                            }
                          >
                            <Menu.Item key="portal/compliance/soc1">
                              <NavLink to="/portal/compliance/soc1" className="padding-left-sidebar">
                                {this.handleLinkColor("soc1", "sidebar.portal.compliance.soc1")}
                              </NavLink>
                            </Menu.Item>
                            <Menu.Item key="portal/compliance/compliancemanagement/personnel">
                              <NavLink
                                activeStyle={{
                                  borderBottom: "solid 3px #fff",
                                  paddingBottom: "1em"
                                }}
                                to={
                                  "/portal/compliance/compliancemanagement/personnel"
                                }
                                className="padding-left-sidebar"
                              >
                                {this.handleLinkColor(
                                  "complianceManagement",
                                  "sidebar.portal.compliance.complianceManagement.personnel"
                                )}
                              </NavLink>
                            </Menu.Item>
                            <Menu.Item key="portal/compliance/compliancemanagement/roles">
                              <NavLink
                                activeStyle={{
                                  borderBottom: "solid 3px #fff",
                                  paddingBottom: "1em"
                                }}
                                to={"/portal/compliance/compliancemanagement/roles"}
                                className="padding-left-sidebar"
                              >
                                {this.handleLinkColor(
                                  "complianceManagement",
                                  "sidebar.portal.compliance.complianceManagement.roles"
                                )}
                              </NavLink>
                            </Menu.Item>
                            <Menu.Item key="portal/compliance/compliancemanagement/vendors">
                              <NavLink
                                activeStyle={{
                                  borderBottom: "solid 3px #fff",
                                  paddingBottom: "1em"
                                }}
                                to={
                                  "/portal/compliance/compliancemanagement/vendors"
                                }
                                className="padding-left-sidebar"
                              >
                                {this.handleLinkColor(
                                  "complianceManagement",
                                  "sidebar.portal.compliance.complianceManagement.vendors"
                                )}
                              </NavLink>
                            </Menu.Item>

                            <Menu.Item key="portal/compliance/compliancemanagement/questionnaire">
                              <NavLink
                                activeStyle={{
                                  borderBottom: "solid 3px #fff",
                                  paddingBottom: "1em"
                                }}
                                to={
                                  "/portal/compliance/compliancemanagement/questionnaire"
                                }
                                className="padding-left-sidebar"
                              >
                                {this.handleLinkColor(
                                  "complianceManagement",
                                  "sidebar.portal.compliance.complianceManagement.questionnaire"
                                )}
                              </NavLink>
                            </Menu.Item>
                            <SubMenu
                              id="deviceManagementSubMenu"
                              key="deviceManagementSubMenu"
                              activestyle={{
                                color: "orange"
                              }}
                              title={
                                (addons && addons.includes("soc2")) ||
                                  (addons && addons.includes("iso27001")) ||
                                  (addons && addons.includes("soc1")) ? (
                                  <span>
                                    <span
                                      style={{
                                        marginLeft: "10px",
                                        position: "relative",
                                        bottom: "2px",
                                        right: "13px"
                                      }}
                                    >
                                      {" "}
                                      <span className="submenu-unlocked-link-color">
                                        <IntlMessages id="sidebar.portal.compliance.complianceManagement.deviceManagement" />{" "}
                                      </span>
                                    </span>
                                  </span>
                                ) : (
                                  <span className="locked-link-color">
                                    <span
                                      style={{
                                        marginLeft: "10px",
                                        position: "relative",
                                        bottom: "2px",
                                        right: "13px"
                                      }}
                                    >
                                      {" "}
                                      <IntlMessages id="sidebar.portal.compliance.complianceManagement.deviceManagement" />{" "}
                                    </span>
                                  </span>
                                )
                              }
                            >
                              <Menu.Item key="portal/compliance/compliancemanagement/devicemanagement/deviceauthorization">
                                <NavLink
                                  activeStyle={{
                                    borderBottom: "solid 3px #fff",
                                    paddingBottom: "1em"
                                  }}
                                  to={
                                    "/portal/compliance/compliancemanagement/devicemanagement/deviceauthorization"
                                  }
                                  className="padding-left-sidebar"
                                >
                                  {this.handleLinkColor(
                                    "complianceManagement",
                                    "sidebar.portal.compliance.complianceManagement.deviceManagement.deviceAuthorization"
                                  )}
                                </NavLink>
                              </Menu.Item>
                              <Menu.Item key="portal/compliance/compliancemanagement/devicemanagement/devices">
                                <NavLink
                                  activeStyle={{
                                    borderBottom: "solid 3px #fff",
                                    paddingBottom: "1em"
                                  }}
                                  to={
                                    "/portal/compliance/compliancemanagement/devicemanagement/devices"
                                  }
                                  className="padding-left-sidebar"
                                >
                                  {this.handleLinkColor(
                                    "complianceManagement",
                                    "sidebar.portal.compliance.complianceManagement.deviceManagement.devices"
                                  )}
                                </NavLink>
                              </Menu.Item>
                            </SubMenu>
                          </SubMenu>
                        )}
                      </SubMenu>
                      <SubMenu
                        key="security"
                        className={this.getNavStyleSubMenuClass(navStyle)}
                        onTitleClick={() => {
                          if (cloudType === "aws") {
                            this.props.history.push('/portal/compliance/security');
                          } else {
                            this.props.history.push('/portal/compliance/security');
                          }
                        }}
                        title={
                          <span>
                            <Icon
                              type="safety-certificate"
                              style={{
                                fontSize: "20px",
                                color: "DodgerBlue",
                                marginTop: ".5em",
                                marginRight: "1em"
                              }}
                            />
                            <span className={`submenu-unlocked-link-color ${openKeys.includes('security') ? 'active-main-title' : ''}`}>
                              <IntlMessages id="sidebar.security" />
                            </span>
                          </span>
                        }
                      >
                        <Menu.Item key="portal/compliance/security">
                          <NavLink to="/portal/compliance/security" className="padding-left-sidebar">
                            <span className="submenu-unlocked-link-color">
                              <IntlMessages id="sidebar.secu" />
                            </span>
                          </NavLink>
                        </Menu.Item>
                        {getSecurityMenuItems(addons, cloudType)}
                      </SubMenu>

                      <Menu.Item key="portal/compliance/assetinventory">
                        <NavLink
                          activeStyle={{
                            borderBottom: "solid 3px #fff",
                            paddingBottom: "1em"
                          }}
                          to={"/portal/compliance/assetinventory"}
                          className="padding-left-sidebar"
                        >
                          {this.handleLinkColorWithImage(
                            "sidebar.cloudresources",
                            "cloud"
                          )}
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="portal/pentest/dashboard"
                        className={`${pathname.includes('/portal/pentest') || pathname.includes('/portal/compliance/pentest')
                          ? 'ant-menu-item-selected menu-item-active'
                          : ''
                          }`}
                      >
                        <NavLink
                          activeStyle={{
                            borderBottom: "solid 3px #fff",
                            paddingBottom: "1em",
                            color: "#1890ff",  // Add blue color for active state
                            fontWeight: "500"  // Make text slightly bolder when active
                          }}
                          to={"/portal/pentest/dashboard"}
                          className={`padding-left-sidebar ${pathname.includes('/portal/pentest') || pathname.includes('/portal/compliance/pentest')
                            ? 'active-nav-link'
                            : ''
                            }`}
                        >
                          {this.handleChangingLinkColorWithImage(
                            "pentest",
                            "sidebar.pentest",
                            require("assets/images/pen/pen2_grey.png").default,
                            require("assets/images/pen/pen2_blue.png").default,
                            "WebApp Scanner"
                          )}
                        </NavLink>
                      </Menu.Item>
                      {cloudType === "aws" ? (
                        <Menu.Item key="portal/costsavings">
                          <NavLink
                            activeStyle={{
                              borderBottom: "solid 3px #fff",
                              paddingBottom: "1em"
                            }}
                            to={"/portal/costsavings"}
                            className="padding-left-sidebar"
                          >
                            {this.handleLinkColorWithImage(
                              "sidebar.costsavings",
                              "dollar"
                            )}
                          </NavLink>
                        </Menu.Item>
                      ) : null}
                      <SubMenu
                        key="account"
                        className={this.getNavStyleSubMenuClass(navStyle)}
                        activestyle={{
                          color: "orange"
                        }}
                        title={
                          <span className=" align-middle">
                            <Icon
                              type="solution"
                              style={{
                                fontSize: "20px",
                                color: "DodgerBlue",
                                marginTop: ".5em",
                                marginRight: "1em"
                              }}
                            />{" "}
                            <span className="submenu-unlocked-link-color">
                              <IntlMessages
                                className="align-middle"
                                id="sidebar.account"
                              />
                            </span>
                          </span>
                        }
                      >
                        <Menu.Item key="account/cloudaccounts">
                          <NavLink
                            activestyle={{
                              color: "orange"
                            }}
                            to="/account/cloudaccounts"
                            className="padding-left-sidebar"
                          >
                            <span className="submenu-unlocked-link-color">
                              <IntlMessages id="sidebar.account.cloudaccounts" />
                            </span>
                          </NavLink>
                        </Menu.Item>
                        {user_type !== null &&
                          typeof user_type !== "undefined" &&
                          user_type !== "subuser" ? (
                          <Menu.Item key="account/usersettings/notifications">
                            <NavLink
                              activeStyle={{
                                borderBottom: "solid 3px #fff",
                                paddingBottom: "1em"
                              }}
                              to="/account/usersettings/notifications"
                            >
                              <span className="submenu-unlocked-link-color">
                                <IntlMessages id="sidebar.account.notifications" />
                              </span>
                            </NavLink>
                          </Menu.Item>
                        ) : null}
                        {/* Comment out entire UserSettings submenu
                      <SubMenu
                        key="account/usersettings"
                        className={
                          (this.getNavStyleSubMenuClass(navStyle),
                            "padding-left-sidebar-menu")
                        }
                        activestyle={{
                          color: "orange"
                        }}
                        title={
                          <span className=" align-middle">
                            <Icon
                              type="tool"
                              style={{
                                fontSize: "20px",
                                color: "DodgerBlue",
                                marginTop: ".5em",
                                marginRight: "0.3em"
                              }}
                            />{" "}
                            <span className="submenu-unlocked-link-color">
                              <IntlMessages
                                className="align-left"
                                id="sidebar.account.usersettings"
                              />
                            </span>
                          </span>
                        }
                      >
                        <Menu.Item key="account/usersettings/profile">
                          <NavLink
                            activeStyle={{
                              borderBottom: "solid 3px #fff",
                              paddingBottom: "1em"
                            }}
                            to="/account/usersettings/profile"
                          >
                            <span className="submenu-unlocked-link-color">
                              Profile
                            </span>
                          </NavLink>
                        </Menu.Item>
                        
                      </SubMenu>
                      */}
                        {user_type !== null &&
                          typeof user_type !== "undefined" &&
                          user_type !== "subuser" ? (
                          <Menu.Item
                            key="account/subusers"
                            disabled={
                              user_type !== null &&
                              typeof user_type !== "undefined" &&
                              user_type === "subuser"
                            }
                          >
                            <NavLink
                              activestyle={{
                                color: "orange"
                              }}
                              to="/account/subusers"
                              className="padding-left-sidebar"
                            >
                              <span className="submenu-unlocked-link-color">
                                <IntlMessages id="sidebar.account.subusers" />
                              </span>
                            </NavLink>
                          </Menu.Item>
                        ) : null}



                        <Menu.Item key="account/auditormanagement">
                          <NavLink
                            activestyle={{
                              color: "orange"
                            }}
                            to="/account/auditormanagement"
                            className="padding-left-sidebar"
                          >
                            {this.handleLinkColor(
                              "soc1",
                              "sidebar.account.auditormanagement"
                            )}
                          </NavLink>
                        </Menu.Item>
                        {user_type !== null &&
                        typeof user_type !== "undefined" &&
                        user_type !== "subuser" ? (
                        <Menu.Item key="account/addons">
                          <NavLink
                            activeStyle={{
                              borderBottom: "solid 3px #fff",
                              paddingBottom: "1em"
                            }}
                            to="/account/addons"
                            className="padding-left-sidebar"
                          >
                            <span className="submenu-unlocked-link-color">
                              <IntlMessages id="sidebar.account.addons" />
                            </span>
                          </NavLink>
                        </Menu.Item>
                      ) : null}
                        {/* {user_type !== null &&
                        typeof user_type !== "undefined" &&
                        user_type !== "subuser" ? (
                        <Menu.Item key="account/invoices">
                          <NavLink
                            to="/account/invoices"
                            className="padding-left-sidebar"
                          >
                            {plan_id && plan_id !== "free" ? (
                              <span className="submenu-unlocked-link-color">
                                <IntlMessages id="sidebar.account.invoices" />
                              </span>
                            ) : (
                              <span className="locked-link-color">
                                <IntlMessages id="sidebar.account.invoices" />
                              </span>
                            )}
                          </NavLink>
                        </Menu.Item>
                      ) : null} */}
                        <Menu.Item key="icompaas-support">
                          <a
                            href={getWhiteLabelSupportMeetingLink()}
                            target="_blank"
                            className="padding-left-sidebar"
                          >
                            <span className="submenu-unlocked-link-color">
                              <IntlMessages id="sidebar.account.support" />
                              &nbsp;&nbsp;
                            </span>
                            <i
                              className="fas fa-external-link-alt"
                              style={{ color: "dodgerblue" }}
                            ></i>
                          </a>
                        </Menu.Item>
                      </SubMenu>

                    </Menu>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};

const mapStateToProps = ({ settings, PlanId, PlanDetails, cloudType }) => {
  const {
    navStyle,
    themeType,
    locale,
    pathname,
    navOpen,
    sidebarKeys,
    menuState
  } = settings;
  const { name } = PlanId;
  const { details } = PlanDetails;
  return {
    navStyle,
    themeType,
    locale,
    pathname,
    name,
    details,
    navOpen,
    cloudType,
    sidebarKeys,
    settings
  };
};

const mapDispatchToProps = dispatch => {
  return {
    PlanId: name => dispatch({ type: "PlanId", payload: name }),
    PlanDetails: details => dispatch({ type: "PlanDetails", payload: details })
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SidebarContent));
