import axios from "axios";

/* Runs each time plan_id is needed to check if it is already 
saved in Redux or needs to be called from backend to save */
const PlanId = async (name) => {
    let plan_id;
    if(name !== ''){ plan_id = name }
    else if(name === '') {
        await axios.get("/backend/constant/getPlanId").then(res => { plan_id = res.data });
    }
    return plan_id;
}

export default PlanId;
