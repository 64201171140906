import React from "react";
import CodeBlockStyled from "../CodeBlockStyled";
import macOS_step2Img from "../../../assets/images/deviceAgent/macOS_step2.png";
import macOS_step7Img from "../../../assets/images/deviceAgent/macOS_step7.jpg";
import DownloadButton from "./DownloadButton";

function MacOSGuide(props) {
    return (
        <>
            <h2>OSQuery installation for Mac OS</h2>
            <ol className="guide-ol">
                <li>
                    <p>
                        Download OSQuery from the download button below.
                        This links directly to the osquery installer. 
                        You may be prompted to save the file or it may start immediately downloading.
                    </p>
                    <DownloadButton 
                        link="https://pkg.osquery.io/darwin/osquery-5.0.1.pkg" 
                        text="Download OSQuery" 
                        showLoading={false}
                    />
                </li>
                <li>
                    <p>
                        Run the osquery installer you just downloaded. 
                        The installer will guide you through the installation step-by-step.
                    </p>
                    <div className="guide-img-box">
                        <img src={macOS_step7Img} alt="mac OS install wizard os query"/>
                    </div>
                    <p>
                        Please note:
                        <ul>
                            <li>You need to have administrator privileges to install osquery.</li>
                            <li>
                                <strong>You will still need to apply your employer's configuration to authorize updates from your device. </strong>
                                This is described in the next section.
                            </li>
                        </ul>
                    </p>
                </li>
            </ol>
            <h2>Applying your employer's configuration</h2>
            <ol className="guide-ol">
                <li>
                    <p>
                        Click the button below to download the osconfig installer, mac-config.zip.                      
                        This links directly to the config zip file. 
                        You may be prompted to save the file or it may start immediately downloading. 
                    </p>
                    <DownloadButton 
                        link="/backend/general/deviceManagement/download?os=mac" 
                        text="Download mac-config.zip" 
                        showLoading={true}
                    />
                </li>
                <li>
                    <p>
                        Unzip mac-config.zip, this needs to be unzipped so that you can easily run an included script.
                    </p>
                </li>
                {/* <li>
                    <p>
                        Open Finder and navigate to the unziped mac-config files. 
                        <strong> Rename osquery_process.bat to osquery_process.sh</strong>
                    </p>
                </li> */}
                <li>
                    <p>Open Launchpad and search for Terminal, then open the Terminal app.</p>
                </li>
                <li>
                    <p className="code-block-type-inline-outer">
                        In the Terminal, navigate to the unzipped directory and run this command <CodeBlockStyled text={`sudo sh osquery_process.sh`} size="inline" />
                    </p>
                </li>
                {/* <li>
                    <p className="code-block-type-inline-outer">
                        Run the config script by copying and pasting this command to the Terminal then press enter  
                        <CodeBlockStyled text={`cd ~/Downloads ; cd "$(ls -t | grep mac-config | head -n 1)" ; mv osquery_process.bat osquery_process.sh ; sh osquery_process.sh ;`} size="inline" />
                    </p>
                </li> */}
                <li>
                    <p className="code-block-type-inline-outer">
                        Make sure osqueryctl has started by using this command in the Terminal   
                        <CodeBlockStyled text={`sudo osqueryctl status`} size="inline" />
                    </p>
                    <p>Please contact your administrator if you encounter any errors during installation. </p>
                </li>
            </ol>
        </>
    );
}

export default MacOSGuide;
