import React from "react";
import { Route, Switch } from "react-router-dom";
import Settings from "./usersettings";
import PlanDetails from "./plandetails";
import SubUsers from "./subusers";
import PlansAndBilling from "./plansandbilling";
import CloudAccounts from "./cloudaccounts";
import CloudAccountSetup from "./cloudaccountsetup";
import Progress from "./progress";
import Addons from "./addons";
import SubscriptionConfirmation from "./subscriptionconfirmation";
import AuditorManagement from "./auditormanagement";
import Invoices from "./invoices";


const Main = ({ match }) => (
  <Switch>
 
    <Route path={`${match.url}/usersettings`} component={Settings}/>
    <Route path={`${match.url}/plandetails`} component={PlanDetails}/>
    <Route path={`${match.url}/plansandbilling`} component={PlansAndBilling}/>
    <Route path={`${match.url}/cloudaccounts`} component={CloudAccounts}/>
    <Route path={`${match.url}/cloudaccountsetup`} component={CloudAccountSetup}/>
    <Route path={`${match.url}/progress`} component={Progress}/>
    <Route path={`${match.url}/subusers`} component={SubUsers}/>
    <Route path={`${match.url}/addons`} component={Addons}/>
    <Route path={`${match.url}/subscriptionconfirmation`} component={SubscriptionConfirmation}/>
    <Route path={`${match.url}/auditormanagement`} component={AuditorManagement}/>
    <Route path={`${match.url}/invoices`} component={Invoices}/>

  </Switch>
);

export default Main;
