import React from "react";
import {Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Settings = ({match}) => (
  <Switch>
  <Route path={`${match.url}/profile`} component={asyncComponent(() => import('./src/index'))}/>
  <Route
      path={`${match.url}/notifications`}
      component={asyncComponent(() => import("./notifications/src/index"))}
    />
  </Switch>
);

export default Settings;
