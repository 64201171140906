import React, { Component } from "react";
import { connect } from "react-redux";
import URLSearchParams from "@ungap/url-search-params";
import { Redirect, Route, Switch } from "react-router-dom";
import ConfigProvider from "antd/es/config-provider";
import { IntlProvider } from "react-intl";
import PropTypes from "prop-types";
import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SubUserActivation from "../SubUserActivation";
import ForgotPassword from "../ForgotPassword";
import ResetPassword from "../ResetPassword";
import SystemFailure from "../../components/SystemFailure";
import FreeTrialExpired from "../../components/FreeTrialExpired";
import { INIT_URL } from "constants/ActionTypes";
import {
  LAYOUT_TYPE,
  NAV_STYLE,
  THEME_TYPE
} from "../../constants/ThemeSetting";
import SLogin from "containers/SLogin";
import deviceAgent from "routes/deviceAgent/index";
import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

class App extends Component {
  setLayoutType = layoutType => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("full-layout");
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove("full-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("boxed-layout");
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("full-layout");
      document.body.classList.add("framed-layout");
    }
  };

  setNavStyle = navStyle => {
    if (
      navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add("full-scroll");
      document.body.classList.add("horizontal-layout");
    } else {
      document.body.classList.remove("full-scroll");
      document.body.classList.remove("horizontal-layout");
    }
  };

  UNSAFE_componentWillMount() {
    if (this.props.initURL === "") {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    const params = new URLSearchParams(this.props.location.search);

    if (params.has("theme")) {
      this.props.setThemeType(params.get("theme"));
    }
    if (params.has("nav-style")) {
      this.props.onNavStyleChange(params.get("nav-style"));
    }
    if (params.has("layout-type")) {
      this.props.onLayoutTypeChange(params.get("layout-type"));
    }
  }

  render() {
    const {
      match,
      location,
      layoutType,
      navStyle,
      locale,
      authUser,
      initURL
    } = this.props;
    if (location.pathname === "/") {
      if (authUser === null) {
        return <Redirect to={"/signin"} />;
      } 
      else if (
        initURL === "" ||
        initURL === "/" ||
        initURL === "/signin" ||
        initURL === "/password/reset/" ||
        initURL === "/support/slogin/" ||
        initURL === "/partnersignin" ||
        initURL === "/subuseractivation"
      ) {
        if (this.props.needed === "true" || sessionStorage.getItem("cloudAccountNeeded") === "true") {
          return <Redirect to={"/account/cloudaccountsetup"} />;
        } else {
          return <Redirect to={"/portal/dashboard"} />;
        }
      } else {
        if (this.props.needed === "true" || sessionStorage.getItem("cloudAccountNeeded") === "true") {
          return <Redirect to={"/account/cloudaccountsetup"} />;
        } else {
          return <Redirect to={initURL} />;
        }
      }
    } 
    this.setLayoutType(layoutType);
    this.setNavStyle(navStyle);

    const currentAppLocale = AppLocale[locale.locale];

    return (
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <Switch>
            <Route exact path="/signin" component={SignIn} />
            <Route
              exact
              path="/subuseractivation"
              component={SubUserActivation}
            />
            <Route exact path="/partnersignin" component={SignIn} />
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route exact path="/password/reset" component={ResetPassword} />
            <Route exact path="/support/slogin" component={SLogin} />

            {/* Device Agent does not use login, sidebar, or other common components. So it is seperate */}
            <Route path="/deviceagent" component={deviceAgent} />
            
            <Route path="/systemfailure" component={SystemFailure} />

            <Route path="/free_trial_expired" component={FreeTrialExpired} />

            <RestrictedRoute
              path={`${match.url}`}
              authUser={authUser}
              component={MainApp}
            />
          </Switch>
        </IntlProvider>
      </ConfigProvider>
    );
  }
}
App.contextTypes = {
  router: PropTypes.object.isRequired
};
const mapStateToProps = ({ settings, auth, CloudAccount }) => {
  const { locale, navStyle, layoutType } = settings;
  const { authUser, initURL } = auth;
  const { needed } = CloudAccount;
  return { locale, navStyle, layoutType, authUser, initURL, needed };
};

const mapDispatchToProps = dispatch => {
  return {
    neededTrue: () => dispatch({ type: "neededTrue" }),
    neededFalse: () => dispatch({ type: "neededFalse" }),
    setInitUrl: url => dispatch({ type: INIT_URL, payload: url }),
    setThemeType: themeType => dispatch({ type: THEME_TYPE, themeType }),
    onNavStyleChange: navStyle => dispatch({ type: NAV_STYLE, navStyle }),
    onLayoutTypeChange: layoutType =>
    dispatch({ type: LAYOUT_TYPE, layoutType })
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(App);
