import axios from "axios";

export const getIsWhiteLabelAppPartner = () => {
    if (nonPersistentStorageObject.app_white_label.is_partner) {
        return true;
    }
    return false;
}

export const getIsWhiteLabelAccountPartner = () => {
    if (sessionStorage.getItem("logoUrl")) {
        return true;
    }
    return false;
}

export const getIsWhiteLabelPartner = () => {
    // return true when the domain is a partner, or the account is a partner
    if (
        getIsWhiteLabelAppPartner() ||
        getIsWhiteLabelAccountPartner()
    ) {
        return true;
    }
    return false;
}

export const getWhiteLabelLogoUrl = () => {
    // if there is account level whitelabelling
    // then use the account whitelabel logo 
    const accountLogoUrl = sessionStorage.getItem("logoUrl");
    if (accountLogoUrl) {
        return accountLogoUrl;
    }
    // otherwise return the app level whitelabel logo
    return nonPersistentStorageObject.app_white_label.logo_url;
}

export const getWhiteLabelLogoBase64 = async () => {
    const logoUrl = getWhiteLabelLogoUrl();
    try {
        const logoBase64Res = await axios.post(
            "/backend/constant/base64ImageFromUrl", 
            {
                imageUrl: logoUrl
            }
        );
        return logoBase64Res.data;
    } catch(error) {
        return null;
    }
}

export const getWhiteLabelPartnerName = () => {
    return nonPersistentStorageObject.app_white_label.partner_name;
}

export const getWhiteLabelSafePartnerName = () => {
    return nonPersistentStorageObject.app_white_label.safe_partner_name;
}

export const getWhiteLabelCopyrightName = () => {
    return nonPersistentStorageObject.app_white_label.copyright_name;
}

export const getWhiteLabelSupportEmail = () => {
    return nonPersistentStorageObject.app_white_label.support_email;
}

export const getWhiteLabelSupportMeetingLink = () => {
    return nonPersistentStorageObject.app_white_label.support_meeting_link;
}

export const getWhiteLabelMainWebsiteUrlBase = () => {
    return nonPersistentStorageObject.app_white_label.main_website_url_base;
}
