import React from "react";
import WarningMessage from "./WarningMessage";

const NoCloudAccount = () => {
    return (
        <WarningMessage
            title={(
                <>
                    A cloud account needs to be added to see data.{" "}
                    <a href="/account/cloudaccountsetup"> Setup a cloud account </a>
                    to get started.
                </>
            )}
            style={{ marginBottom: "15px" }}
        />
    );
};

export default NoCloudAccount;
