import React, { useState } from "react";

const DownloadButton = props => {
    const [ buttonText, setButtonText ] = useState(props.text)
    const [ buttonDisabled, setButtonDisabled ] = useState(false)

    const handleClick = () => {  
        if(props.showLoading === true) {
            disableDownload();
            checkForDownload();
        }
    }
    
    const checkForDownload = () => {
        let checkCount = 0;
        /* 
            There is a cookie returned when file is ready to download
            Check for that cookie every second, up to 10 seconds
                If it is returned then go back to initial button state
                Else keep checking till 10, at 10 seconds go back to initial button state  
        */
        let checkerInterval = window.setInterval(() => {
            if(checkerInterval && checkCount > 10) {
                enableDownload();
                window.clearInterval(checkerInterval);
            } 
            let cookieParts = document.cookie.split("file-downloading=");
            if(cookieParts.length === 2) {
                if (cookieParts[1] === "true") {
                    checkCount = 10;
                    window.clearInterval(checkerInterval);
                    handleDownloadStarted();
                } else if (cookieParts[1] === "false") {
                    checkCount = 10;
                    window.clearInterval(checkerInterval);
                    handleDownloadStarted();
                    window.location.replace("/deviceagent/install/validate")
                } 
            } else {
                checkCount++;
            }
        }, 1000);
    }

    const handleDownloadStarted = () => {
        document.cookie = "file-downloading=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        enableDownload();
    }

    const disableDownload = () => {
        document.getElementById("install-link").classList.add("disabled-link");
        setButtonText("Preparing file for download...");
        setButtonDisabled(true)
    }

    const enableDownload = () => {
        document.getElementById("install-link").classList.remove("disabled-link");
        setButtonText(props.text);
        setButtonDisabled(false)
    }

    return (
        <a 
            id="install-link" 
            href={props.link} 
            download
        >
            <button 
                className="install-button" 
                onClick={() => handleClick()} 
                disabled={buttonDisabled}
            >
                <i className="fas fa-download"></i>&nbsp;
                {buttonText}
            </button>
        </a>
    );
}

export default DownloadButton;
