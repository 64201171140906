import "react-hot-loader/patch";
import React from "react";
import ReactDOM from "react-dom";
import NextApp from "./NextApp";
// import registerServiceWorker from "./registerServiceWorker";
// import { subscribePushManager} from "./subscription";

// Wrap the rendering in a function:
const render = Component => {
  ReactDOM.render(
    <NextApp />,
    document.getElementById("root")
  );
};

// Do this once
// registerServiceWorker();

// Render once
render(NextApp);

//Setting for Logging in dev
if (process.env.NODE_ENV !== 'production') {
  sessionStorage.setItem('debug', 'icompaas_react_app:*');
}

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./NextApp", () => {
    render(NextApp);
  });
}

// subscribePushManager();
