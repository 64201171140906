import React from "react";
import { Route, Switch } from "react-router-dom";
import validateUser from "./validateUser/index";
import installGuide from "./installGuide/index";

const Install = ({match}) => (
  <Switch>
    <Route path={`${match.url}/validate`} component={validateUser}/>
    <Route exact path={`${match.url}/`} component={installGuide}/>
  </Switch>
);

export default Install;