import axios from "axios";
import baseOC from "icfrontendbase";

// The shared storage needs to know which addons the user is subscribed to.
// getSubscribedAddonsLegacyContract is used so that any time the legacy code gets the addons list, shared storage is updated


const _updateSharedStorage = async (subscribedAddonList) => {
    const sharedStorage = baseOC.get("SharedStorage");
    let newSSAddon = await sharedStorage.get("addon");
    Object.keys(newSSAddon).forEach(ssAddonKey => {
        // if the user is subscribed, then they have access
        if (subscribedAddonList.includes(ssAddonKey)) {
            newSSAddon[ssAddonKey].has_access = true;
            return;
        }
        newSSAddon[ssAddonKey].has_access = false;
    })
    await sharedStorage.set("addon", newSSAddon);
    return
}

/*
    _getAddons return example:
    {
        raw_res: "cislevel1,cislevel2,hipaa",
        addon_list: ["cis", "hipaa"]
    }
*/
const _getAddons = async () => {
    const addonsRes = await axios.get("/backend/constant/addons");
    
    let addonList = [];
    addonsRes.data.split(",").forEach(complianceCode => {
        // if the compliance code is one of the cis scans AND cis is not already added to the list
        // then add cis to the addon list
        if (            
            (complianceCode === "cislevel1" || complianceCode === "cislevel2")
            && addonList.includes("cis") === false
        ) {
            addonList.push("cis");
            return;
        }

        // otherwise add the compliance code as a subscribed addon
        addonList.push(complianceCode)
    })

    return {
        raw_res: addonsRes,
        addon_list: addonList
    };
}

const getSubscribedAddonsLegacyContract = async () => {
    const { raw_res, addon_list } = await _getAddons();
    _updateSharedStorage(addon_list);
    return raw_res;
}

const getSubscribedAddons = async () => {
    const { addon_list } = await _getAddons();
    _updateSharedStorage(addon_list);
    return addon_list;
}

export { getSubscribedAddonsLegacyContract };
export default getSubscribedAddons;