import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Addons = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/`}
      component={asyncComponent(() => import("./src/index"))}
    />
  </Switch>
);

export default Addons;
