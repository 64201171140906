import React, { Component } from "react";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import { getMsgData } from "../util/functions";
import { handleGeneralErrorMessage } from "../util/functions";
import IntlMessages from "util/IntlMessages";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Background from "assets/images/mission2.png";
import { getWhiteLabelPartnerName, getWhiteLabelLogoUrl } from "util/whitelabel";

const FormItem = Form.Item;

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      ac: null,
      uc: null,
      redirectToSignin: false,
      disabled: true,
      loading:false
    };
  }

  handleOnFocus = () => {
    if (this.state.disabled) {
      this.setState({ disabled: false });
    }
  };

  async componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    let uc = urlParams.get("uc");
    let ac = urlParams.get("ac");
    let email = urlParams.get("email");

    try {
      let result = await axios({
        method: "post",
        headers: {
          "content-type": "application/json"
        },
        url: "/backend/account/forgotPasswordValidate",
        data: { ac: ac, uc: uc }
      });

      if (result && result.data && result.data.status === "success") {
        this.setState({ validated: true, ac: ac, uc: uc, email: email });
      }
    } catch (err) {
      handleGeneralErrorMessage(err);
    }
  }

  handleSubmit = async e => {
    var { ac, uc } = this.state;
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err && values) {
        this.setState({loading:true})
        try {
          let result = await axios({
            method: "post",
            headers: {
              "content-type": "application/json"
            },
            url: "/backend/account/passwordReset",
            data: {
              ac: ac,
              uc: uc,
              email: values.email,
              password: values.password,
              confirm_password: values.confirm
            }
          });

          if (result && result.data && result.data.status === "success") {
            getMsgData("passwordResetSuccess");
            this.setState({ redirectToSignin: true });
          }
          this.setState({loading:false})
        } catch (error) {
          this.setState({loading:false})
          handleGeneralErrorMessage(error);
        }
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.props.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { validated, redirectToSignin, disabled } = this.state;
    if (redirectToSignin === true) {
      return <Redirect to="/signin" />;
    } else
      return (
        <div
          className="gx-login-container"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundPosition: "right",
            backgroundRepeat: "no-repeat"
          }}
        >
          {validated ? (
            <div className="gx-login-content">
              <div className="gx-login-header">
                <img
                  src={getWhiteLabelLogoUrl()}
                  alt={getWhiteLabelPartnerName()}
                  title={getWhiteLabelPartnerName()}
                />
              </div>
              <div className="gx-mb-4">
                <h2>Reset Password</h2>
                <p>
                  <IntlMessages id="appModule.enterPasswordReset" />
                </p>
              </div>

              <Form
                onSubmit={this.handleSubmit}
                className="gx-login-form gx-form-row0"
              >
                <FormItem>
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your email id"
                      }
                    ]
                  })(
                    <Input
                      type="email"
                      readOnly={disabled}
                      onFocus={this.handleOnFocus}
                      placeholder="Email"
                    />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("password", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your password!"
                      },
                      {
                        validator: this.validateToNextPassword
                      }
                    ]
                  })(
                    <Input
                      type="password"
                      readOnly={disabled}
                      onFocus={this.handleOnFocus}
                      placeholder="New Password"
                    />
                  )}
                </FormItem>

                <FormItem>
                  {getFieldDecorator("confirm", {
                    rules: [
                      {
                        required: true,
                        message: "Please confirm your password!"
                      },
                      {
                        validator: this.compareToFirstPassword
                      }
                    ]
                  })(
                    <Input
                      placeholder="Retype New Password"
                      type="password"
                      readOnly={disabled}
                      onFocus={this.handleOnFocus}
                      onBlur={this.handleConfirmBlur}
                    />
                  )}
                </FormItem>

                <FormItem>
                  <Button loading={this.state.loading} type="primary" htmlType="submit">
                    <IntlMessages id="app.userAuth.reset" />
                  </Button>
                </FormItem>
              </Form>
            </div>
          ) : (
            <div className="gx-login-content">
              <div className="gx-login-header">
                <img
                  src={getWhiteLabelLogoUrl()}
                  alt={getWhiteLabelPartnerName()}
                  title={getWhiteLabelPartnerName()}
                />
              </div>
              <div className="gx-mb-4">
                <h2>Reset Password</h2>
              </div>{" "}
              <span>
                {" "}
                Forgot Password Link could not be validated, Please contact
                Customer Service!!
              </span>{" "}
            </div>
          )}
        </div>
      );
  }
}

const WrappedResetPasswordForm = Form.create()(ResetPassword);

export default WrappedResetPasswordForm;
