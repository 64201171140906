import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { supportUserSignIn } from "appRedux/actions/Auth";
import CircularProgress from "components/CircularProgress";
import apiClientOC from "icfrontendapiclient";
import baseOC from "icfrontendbase";

const bizApiClient = apiClientOC.get("BizApiClient");
bizApiClient.registerEndpoint("support_signin", "post", "/auth/support_signin");
const sharedStorage = baseOC.get("SharedStorage");

class SLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }
  
  async componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    const account_id = urlParams.get("account_id");
    const user_id = urlParams.get("user_id");
    const support_id = urlParams.get("support_id");

    if (
      (typeof account_id === "undefined" || account_id === null) || 
      !user_id || 
      !support_id 
    ) return;

    try {
      // this is temporarily using the request class directly to sign in
      // later we should use a support login product component
      let resArr = await Promise.all([
        this.validateSupportLogin(account_id, user_id, support_id),
        bizApiClient.support_signin({ user_id, support_id })
      ]);
      const result = resArr[0];
      const loginDetailslibRes = resArr[1];

      if (loginDetailslibRes?.is_error) {
        throw new Error("Error while signing in")
      }

      sharedStorage.set("auth_is_signed_in", true);
      sharedStorage.set("auth_primary_role", result.primary_role);

      if (result.primary_role === "auditor") {
        window.location.replace("/auditor/login/redirect");
      }
      
      if (result.primary_role === "org") {
        this.props.supportUserSignIn({ account_id, user_id, support_id });
        this.setState({ isLoading: false });
      }
    } catch(error) {

    }
  }

   async validateSupportLogin(account_id, user_id, support_id) {
    return  axios({
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      url: "/backend/account/slogin",
      data: {
        account_id: account_id,
        user_id: user_id,
        support_id: support_id
      }
    }).then(res => res.data);
  }

  render() {
    const { isLoading} = this.state;
   
    return (
      <div> 
        {isLoading && <div className="gx-loader-view gx-loader-position">
          <CircularProgress/>
        </div>} 
      </div> 
    )
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, { supportUserSignIn })(SLogin);
