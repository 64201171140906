import React from "react";
import { message, Icon } from "antd";
import axios from "axios";
import { sha256 } from "js-sha256";
import { constantMessages } from "./Constants";
import { getWhiteLabelLogoUrl } from "util/whitelabel";
import NoCloudAccount from "../components/Messages/NoCloudAccount";
import { store } from "../NextApp";
import moment from "moment";

// logout user and clear redux state
export const signout = async () => {
  // If the user signed in at the partner login page, redirect them to partner login after signout
  const loginRedirectPath = sessionStorage.getItem("loginPathUsed") || "/signin";

  // Send signout request to clear session
  try {
    await axios.post("/backend/constant/logout")
  } catch(error) {}

  // Prevent UI from flashing when redux is cleared
  if (document.querySelector(".ant-drawer")) {
    document.querySelector(".ant-drawer").setAttribute("style", "display: none;")
  }
  
  // clear browser storage
  store.dispatch({ type: "CLEAR_REDUX_STATE" });
  sessionStorage.clear();
  localStorage.clear();

  // route to login
  window.location.replace(loginRedirectPath)
}

export function message_cloud_account() {
  printMsg("destroy", "No_cloud_account_id", null);

  return printMsg(
    "open",
    {
      content: <NoCloudAccount />,
      duration: 20,
      key: "No_cloud_account_id"
    },
    null
  );
}

export function message_session_expired() {
  printMsg("destroy", "session_expired", null);

  return printMsg(
    "open",
    {
      content: (
        <div
          style={{
            verticalAlign: "middle",
            alignItems: "center",
            display: "flex"
          }}
        >
          <Icon type="warning" style={{ color: "orange" }} />
          <span style={{ display: "inline-block" }}>
            Session Expired. Please Sign In.{" "}
          </span>
        </div>
      ),
      duration: 5,
      key: "session_expired"
    },
    null
  );
}

export function input_type_matcher(answer_type) {
  var types_array = {
    enum: "radio",
    text: "text",
    email: "email",
    number: "number",
    date: "date",
    phone: "tel"
  };
  return types_array[answer_type];
}

export function handleComplianceManagementError(error) {

  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.message
  ) {
    printMsg("error", error.response.data.message, 3);
  } else {
    printMsg("error", "Unknown Error", 3);
  }
}

export function handleGeneralErrorMessage(error) {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.status &&
    error.response.data.message
  ) {
    printMsg("error",`${error.response.data.message}`, 5);
  } else {
    printMsg("error", "Unknown Error", 3);
  }
}

export function getDateTime12h() {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  const dateGenerated = new Date();
  let hours = dateGenerated.getHours();
  let minutes = dateGenerated.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return (
    "Generated on: " +
    monthNames[dateGenerated.getMonth()] +
    " " +
    dateGenerated.getDate() +
    ", " +
    dateGenerated.getFullYear() +
    " @ " +
    hours +
    ":" +
    minutes +
    " " +
    ampm
  );
}

export function getCloudFormationURL(
  account_id,
  ICOMP_ACCOUNT_NUMBER,
  ICOMP_CLOUD_FORMATION_STACK_URL
) {
  const sha256_account_id = sha256(account_id);
  const ic_account_number = ICOMP_ACCOUNT_NUMBER;

  return (
    "https://us-east-1.console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/create/review?templateURL=" +
    ICOMP_CLOUD_FORMATION_STACK_URL +
    "&stackName=IndependentAuditorAccessRole&param_AccountId=" +
    ic_account_number +
    "&param_ExternalId=" +
    sha256_account_id
  );
}

// takes an array of error messages and an alternative title if array is empty
export function displayErrorList(errArr, altMsg) {
  let msg;
  if (errArr.length > 0) {
    msg = errArr.toString();
    msg = msg.replaceAll(",", ", ");
  } else {
    msg = altMsg;
  }
  printMsg("error", msg, 10);
}

export function getMsgData(name) {
  let msgData = constantMessages[name];
  return printMsg(msgData.type, msgData.msg, msgData.length);
}

export function printMsg(type, msg, length) {
  if (type === "open") {
    return message.open({
      content: msg.content,
      duration: msg.duration,
      key: msg.key
    });
  } else if (type === "destroy") {
    return message.destroy(msg);
  } else {
    return message[type](msg, length);
  }
}

export function copyTextToClipboard(text) {
  let textarea = document.createElement("textarea");
  textarea.textContent = text;
  document.body.appendChild(textarea);
  let selection = document.getSelection();
  let range = document.createRange();
  range.selectNode(textarea);
  selection.removeAllRanges();
  selection.addRange(range);
  document.execCommand("copy");
  selection.removeAllRanges();
  document.body.removeChild(textarea);
}

export function getBasePdfParams(title, file_name) {
  return {
    title,
    file_name,
    tables: []
  };
}

export function checkCloudAccount(title, file_name) {
  let cloudAccountId = sessionStorage.getItem("cloudAccountId");
  return cloudAccountId;
}

// this function should be moved to backend because it has multiple calls, not done now because product api will be coming soon
export const addDevicesTableToPdfParams = async pdfParams => {
  let devices = [];
  let persons = [];
  let rolesArr = [];
  let rolesObj = {};

  await axios
    .get("/backend/general/deviceManagement/device/getSummary")
    .then(res => {
      devices = res.data.devices;
    });
  await axios
    .get("/backend/general/deviceManagement/personnel/getSummary")
    .then(res => {
      persons = res.data;
    });
  await axios
    .get("/backend/general/deviceManagement/role/getRoles")
    .then(res => {
      rolesArr = res.data.roles;
    });

  pdfParams.tables.push({
    title: "Devices :",
    columns: [
      { header: "ID", dataKey: "id" },
      { header: "Serial Number", dataKey: "serial_number" },
      { header: "Assigned To", dataKey: "assigned_to" },
      { header: "OS Name", dataKey: "os_name" },
      { header: "OS Version", dataKey: "os_version" },
      { header: "Overall Encrypted", dataKey: "overall_encrypted" },
      { header: "Drive - Encrypted", dataKey: "drive_encrypted" },
      { header: "Antivirus", dataKey: "antivirus" },
      { header: "Logged In User", dataKey: "logged_in_user" }
    ],
    rows: []
  });

  for (let i = 0; i < rolesArr.length; i++) {
    rolesObj[`${rolesArr[i].role.role_id}`] = rolesArr[i].role.name;
  }

  for (let i = 0; i < devices.length; i++) {
    const device = devices[i].device;
    let id = i + 1;
    let serial_number = device.serial_number;
    let assigned_to = "";
    // check for person, their email, and then name. Prevents null or undefined displayed
    if (device.active_persons[0]) {
      if (device.active_persons[0].email_address) {
        assigned_to += device.active_persons[0].email_address;
      }

      if (
        device.active_persons[0].first_name &&
        device.active_persons[0].last_name
      ) {
        assigned_to +=
          "\n" +
          device.active_persons[0].first_name +
          " " +
          device.active_persons[0].last_name;
      }

      // find the persons summary index that matches the current device person.
      let personsMatchedIndex = null;
      for (let p = 0; p < persons.length; p++) {
        if (
          device.active_persons[0].person_id === persons[p].person.person_id
        ) {
          personsMatchedIndex = persons[p];
        }
      }
      // add the role name of the device's associated person
      if (personsMatchedIndex !== null) {
        if (personsMatchedIndex.role_assocs.length > 0) {
          if (
            rolesObj[`${personsMatchedIndex.role_assocs[0].role_id}`] &&
            rolesObj[`${personsMatchedIndex.role_assocs[0].role_id}`] !== null
          ) {
            assigned_to +=
              "\nRole: " +
              rolesObj[`${personsMatchedIndex.role_assocs[0].role_id}`];
          }
        }
        if (
          personsMatchedIndex.person.title &&
          personsMatchedIndex.person.title !== null
        ) {
          assigned_to += "\nDesignation: " + personsMatchedIndex.person.title;
        }
        if (
          personsMatchedIndex.person.start_date &&
          personsMatchedIndex.person.start_date !== null
        ) {
          assigned_to +=
            "\nStart Date: " + personsMatchedIndex.person.start_date;
        }
      }
    }

    let os_name = "";
    if (device.device_attributes.os_name !== null) {
      os_name = device.device_attributes.os_name.attr_value;
    }

    let os_version = "";
    if (device.device_attributes.os_version !== null) {
      os_version = device.device_attributes.os_version.attr_value;
    }

    let overall_encrypted = "";
    if (device.device_attributes.device_encrypted !== null) {
      overall_encrypted = device.device_attributes.device_encrypted.attr_value.replace(
        /^\w/,
        c => c.toUpperCase()
      );
    }

    let drive_encrypted = "";
    const drives = device.device_attributes.disks;
    // loop through drives and add the letter and encrypted value for each
    for (let d = 0; d < drives.length; d++) {
      drive_encrypted +=
        drives[d].device_resource +
        " - " +
        drives[d].attr_value.replace(/^\w/, c => c.toUpperCase());
      // if not on the last line, then add a line break
      if (d !== drives.length - 1) {
        drive_encrypted += "\n";
      }
    }

    let antivirus = "";
    if (device.device_attributes.antivirus_name !== null) {
      antivirus = device.device_attributes.antivirus_name.attr_value;
    }

    let logged_in_user = "";
    if (device.device_attributes.logged_in_user !== null) {
      logged_in_user = device.device_attributes.logged_in_user.attr_value;
    }

    // add the calculated row data to the device table index
    pdfParams.tables[pdfParams.tables.length - 1].rows.push({
      id,
      serial_number,
      assigned_to,
      os_name,
      os_version,
      overall_encrypted,
      drive_encrypted,
      antivirus,
      logged_in_user
    });
  }

  return pdfParams;
};

export const addPersonnelTableToPdfParams = async pdfParams => {
  let persons = [];
  let rolesArr = [];
  let rolesObj = {};

  await axios
    .get("/backend/general/deviceManagement/personnel/getSummary")
    .then(res => {
      persons = res.data;
    });
  await axios
    .get("/backend/general/deviceManagement/role/getRoles")
    .then(res => {
      rolesArr = res.data.roles;
    });

  pdfParams.tables.push({
    title: "Personnel :",
    columns: [
      { header: "ID", dataKey: "id" },
      { header: "Name", dataKey: "name" },
      { header: "Email Address", dataKey: "email_address" },
      { header: "Designation", dataKey: "designation" },
      { header: "Role", dataKey: "role" },
      { header: "Start Date", dataKey: "start_date" }
    ],
    rows: []
  });

  for (let i = 0; i < rolesArr.length; i++) {
    rolesObj[`${rolesArr[i].role.role_id}`] = rolesArr[i].role.name;
  }

  for (let i = 0; i < persons.length; i++) {
    const person = persons[i].person;
    let id = i + 1;

    let name = "";
    if (person.first_name && person.first_name !== null) {
      name += person.first_name;
    }
    if (person.middle_name && person.middle_name !== null) {
      name += " " + person.middle_name;
    }
    if (person.last_name && person.last_name !== null) {
      name += " " + person.last_name;
    }

    let email_address = "";
    if (person.email_address && person.email_address !== null) {
      email_address = person.email_address;
    }

    let designation = "";
    if (person.title && person.title !== null) {
      designation = person.title;
    }

    let role = "";
    if (persons[i].role_assocs.length > 0) {
      role = rolesObj[`${persons[i].role_assocs[0].role_id}`];
    }

    let start_date = "";
    if (person.start_date && person.start_date !== null) {
      start_date = person.start_date;
    }

    pdfParams.tables[pdfParams.tables.length - 1].rows.push({
      id,
      name,
      email_address,
      designation,
      role,
      start_date
    });
  }

  return pdfParams;
};

export const addRolesTableToPdfParams = async pdfParams => {
  let roles = [];
  await axios
    .get("/backend/general/deviceManagement/role/getRoles")
    .then(res => {
      roles = res.data.roles;
    });

  pdfParams.tables.push({
    title: "Roles :",
    columns: [
      { header: "ID", dataKey: "id" },
      { header: "Name", dataKey: "name" },
      { header: "Description", dataKey: "description" }
    ],
    rows: []
  });

  for (let i = 0; i < roles.length; i++) {
    const role = roles[i].role;
    let id = i + 1;

    let name = "";
    if (role.name && role.name !== null) {
      name = role.name;
    }

    let description = "";
    if (role.description && role.description !== null) {
      description = role.description;
    }

    pdfParams.tables[pdfParams.tables.length - 1].rows.push({
      id,
      name,
      description
    });
  }

  return pdfParams;
};

export const addVendorsTableToPdfParams = async pdfParams => {
  let vendors = [];
  await axios.get("/backend/general/deviceManagement/getVendors").then(res => {
    vendors = res.data.vendors;
  });

  pdfParams.tables.push({
    title: "Vendors :",
    columns: [
      { header: "ID", dataKey: "id" },
      { header: "Name", dataKey: "name" },
      { header: "Type", dataKey: "type" },
      { header: "Services Provided", dataKey: "services_provided" },
      { header: "URL", dataKey: "url" },
      { header: "Start Date", dataKey: "start_date" }
    ],
    rows: []
  });

  for (let i = 0; i < vendors.length; i++) {
    const vendor = vendors[i].vendor;
    let id = i + 1;

    let name = "";
    if (vendor.name && vendor.name !== null) {
      name = vendor.name;
    }

    let type = "";
    if (vendor.type && vendor.type !== null) {
      type = vendor.type;
    }

    let services_provided = "";
    if (vendor.services_provided && vendor.services_provided !== null) {
      services_provided = vendor.services_provided;
    }

    let url = "";
    if (vendor.url && vendor.url !== null) {
      url = vendor.url;
    }

    let start_date = "";
    if (vendor.start_date && vendor.start_date !== null) {
      start_date = vendor.start_date;
    }

    pdfParams.tables[pdfParams.tables.length - 1].rows.push({
      id,
      name,
      type,
      services_provided,
      url,
      start_date
    });
  }

  return pdfParams;
};

/*
  sorts an array of objects ascending based on a nested key
  arr => the array of objects
  path => ['car', 'year'] (the key to sort by)
    [
      {
        'car': {
          'year': '2010'
        },
        'car': {
          'year': '2012'
        }
      }
    ]
*/
export const sortStringArrOfObj = (arr, path) => {
  return arr.sort((a, b) => {
    if (getNestedKeyValue(a, path) < getNestedKeyValue(b, path)) return -1;
    if (getNestedKeyValue(a, path) > getNestedKeyValue(b, path)) return 1;
    return 0;
})
}
export const getNestedKeyValue = (obj, path) => {
  if (path.length === 1) return obj[path[0]];
  if (obj[path[0]]) return getNestedKeyValue(obj[path[0]], path.slice(1));
  else {
    obj[path[0]] = {};
    return getNestedKeyValue(obj[path[0]], path.slice(1));
  }
}

export const checkPlanId = async planId => {
  if (planId !== "") return planId;

  const planIdRes = await axios.get("/backend/constant/getPlanId");
  return planIdRes.data;
}

// get the whitelabel partner logo in base64 data url format
// used in the pdf generators
// return value
//    {
//      data: the_base_64_url,
//      image_type: the_image_file_extension
//    }
export const getBase64WhitelabelImage = async () => {
  try {
    // if image is already data url
    let logoUrl = sessionStorage.getItem("logoUrl");
    if (logoUrl.startsWith("data:")) {
      let content_type = logoUrl.slice(
        logoUrl.indexOf(":"),
        logoUrl.indexOf(";")
      )
      let image_type = content_type.split("/")[1];

      return {
        data: logoUrl,
        image_type: image_type.toUpperCase()
      }
    }

    let base64String = await getImageFromUrl(logoUrl);
    let content_type = base64String && base64String.data && base64String.data.content_type;
    let image_type = content_type && content_type.split("/")[1];

    return {
      data: base64String.data.base64url,
      image_type: image_type.toUpperCase()
    }
  } catch (error) {
    return {}
  }
}

/*
  convertUtcDateTimeToLocalDisplay

  args
    utcDateTime  |  string (required)  |  A UTC datetime string in ISO format

  returns
    {
      date: string, calendar date
      time: string, in 12 hour format
    }

  if utcDateTime is undefined/null/empty string
    then date and time will be empty string 

  if utcDateTime is not a valid datetime
    then date and time will be the current time
*/
export const convertUtcDateTimeToLocalDisplay = (utcDateTime) => {
  let result = { date: "", time: "" };
  if (!utcDateTime) return result;
  const dt = moment.utc(utcDateTime);
  result.time = dt.local().format("h:mm:ss A");
  result.date = dt.local().format("M/D/YYYY");
  return result;
}