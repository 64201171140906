import React, { Component } from "react";
import { Redirect } from "react-router";
import axios from "axios";
import "../../account/spinner.css";

class ValidateUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: "",
            errorText: "",
            loading: false,
            redirectToInstall: false,
            token: null
        }
    }

    componentDidMount = () => {
        const search = window.location.search;
        let token = new URLSearchParams(search).get("token");
        // if no token url param, then check for session stored token
        if(token === null) {
            if(window.sessionStorage.getItem("deviceAgentEmailToken") !== null) {
                token = window.sessionStorage.getItem("deviceAgentEmailToken");
            } else {
                this.setState({ errorText: "Please follow the link that was sent to your email."})
            }
        } else {
            window.sessionStorage.setItem("deviceAgentEmailToken", token);
        }
        this.setState({ token: token });
    }

    handleInputChange = e => {
        this.setState({ email: e.target.value });
    }

    handleFormSubmission = e => {
        e.preventDefault();
        this.setState({ 
            errorText: "",
            loading: true 
        });
        const { email, token } = this.state;
        axios
            .post("/backend/general/deviceManagement/validateUser", {
                "email": email,
                "token": token
            })
            .then(res => {
                if(res.data.userValidated) {
                    this.setState({ 
                        redirectToInstall: true,
                        loading: false 
                    })
                } else {
                    this.setState({ 
                        errorText: "Not able to authenticate the user, please check your Email",
                        loading: false 
                    })
                }
                
            })
            .catch(error => {
                this.setState({ 
                    errorText: "An error occured while processing your request. Please try again.",
                    loading: false  
                })
            })
    }

    render = () => {
        const { redirectToInstall, loading, errorText } = this.state;

        if(redirectToInstall) {
            return <Redirect from="/deviceagent/install/validate" to="/deviceagent/install" />
        } else {
            return (
                <div className="validate-outer">
                    <div className="validate-inner">
                        <h1>Device Agent Installation and Setup Guide</h1>
                        {errorText !== "" ? 
                            <p className="error-text">{errorText}</p> 
                            : null
                        }
                        <form onSubmit={this.handleFormSubmission}>
                            <label htmlFor="email">Email</label>
                            <input 
                                type="email" 
                                name="email"
                                onChange={this.handleInputChange} 
                                placeholder="example@company.com"
                                required
                            />
                            <button type="submit">
                                {loading ? 
                                    <i className="fas fa-spinner"></i>
                                :
                                    "Next"
                                }
                            </button>
                        </form>
                    </div>
                </div>
            );
        }
        
    }
}

export default ValidateUser;