import React, { Component } from "react";
import { connect } from "react-redux";
import Drawer from "antd/es/drawer";
import Layout from "antd/es/layout";
import SidebarContent from "./SidebarContent";
import {
  toggleCollapsedSideNav,
  onNavStyleChange,
  onNavToggle,
} from "appRedux/actions/Setting";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE

} from "../../constants/ThemeSetting";

const { Sider } = Layout;

export class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
   
    };
  }

  onToggleCollapsedNav = () => {
    this.props.toggleCollapsedSideNav(!this.props.navCollapsed);
  
  };

  componentWillUnmount() {
    window.removeEventListener(
      "resize",
      this.setState({ windowWidth: window.innerWidth })
    );
  }

  componentDidMount() {
    this.props.onNavStyleChange(NAV_STYLE_FIXED)
    this.props.onNavToggle(true)
    window.addEventListener("resize", () => this.updateWidowSize());
  }

  updateWidowSize = () => {
    this.setState({ windowWidth: window.innerWidth });

  }

  render() {
    const { themeType, navCollapsed, navStyle ,navOpen} = this.props;

    const { windowWidth } = this.state;

    let drawerStyle = "gx-collapsed-sidebar";

if(windowWidth >= TAB_SIZE && navOpen ===true ){
  this.props.onNavStyleChange(NAV_STYLE_FIXED)
}
if(windowWidth >= TAB_SIZE && navOpen === false ){
  this.props.onNavStyleChange(NAV_STYLE_MINI_SIDEBAR)
}
if(windowWidth < TAB_SIZE ){
  this.props.onNavStyleChange(NAV_STYLE_FIXED)
}


    if (navStyle === NAV_STYLE_FIXED) {
      drawerStyle = "";
    } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      drawerStyle = "gx-mini-sidebar gx-mini-custom-sidebar";
    } else if (navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      drawerStyle = "gx-custom-sidebar";
    } else if (navStyle === NAV_STYLE_MINI_SIDEBAR) {
      drawerStyle = "gx-mini-sidebar";
    } else if (navStyle === NAV_STYLE_DRAWER) {
      drawerStyle = "gx-collapsed-sidebar";
    }
    if (
      (navStyle === NAV_STYLE_FIXED ||
        navStyle === NAV_STYLE_MINI_SIDEBAR ||
        navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) &&
      windowWidth < TAB_SIZE
    ) {
      drawerStyle = "gx-collapsed-sidebar";
    }

    return (
      <Sider
        className={`gx-app-sidebar ${drawerStyle} ${
          themeType !== THEME_TYPE_LITE ? "gx-layout-sider-dark" : null
        }`}
        trigger={null}
        collapsed={
          windowWidth < TAB_SIZE
            ? false
            : navStyle === NAV_STYLE_MINI_SIDEBAR ||
              navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR
        }
        theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
        collapsible
      >
        {navStyle === NAV_STYLE_DRAWER || windowWidth < TAB_SIZE ? (
          <Drawer
            className={`gx-drawer-sidebar ${
              themeType !== THEME_TYPE_LITE ? "gx-drawer-sidebar-dark" : null
            }`}
            placement="left"
            closable={false}
            onClose={this.onToggleCollapsedNav.bind(this)}
            visible={navCollapsed}
          >
            <SidebarContent windowWidth={this.state.windowWidth}/>
          </Drawer>
        ) : (
          <SidebarContent  windowWidth={this.state.windowWidth}/>
        )}
      </Sider>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { themeType, navStyle, navCollapsed, locale,navOpen } = settings;
  return { themeType, navStyle, navCollapsed, locale,navOpen };
};

export default connect(mapStateToProps, {
  onNavStyleChange,
  toggleCollapsedSideNav,
  onNavToggle
})(Sidebar);
