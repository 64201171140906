const init = { name: '' };

const PlanId = (state = init, action) => {
    if(action.type === 'PlanId'){
        state = { name: action.payload };
    }
    else if (action.type === 'PlanIdClear') {
        state = { name: '' }
    }
    return state;
}

export default PlanId;