import React from "react";
import { Route, Switch } from "react-router-dom";
import Compliance from "./compliance";
import CostSavings from "./costsavings";
import Dashboard from "./dashboard";
import CloudResources from "./cloudresources";
import MasterAccount from "./masteraccount";
import PENTestModule from "./pentest";


const Main = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/compliance/assetinventory`} component={CloudResources} />
    <Route path={`${match.url}/compliance`} component={Compliance} />
    <Route path={`${match.url}/dashboard`} component={Dashboard} />
    <Route path={`${match.url}/costsavings`} component={CostSavings} />
    <Route path={`${match.url}/masteraccount`} component={MasterAccount} />
    <Route path={`${match.url}/pentest`} component={PENTestModule} />
  </Switch>
);

export default Main;
