import { useEffect } from "react";
import { signout } from "util/functions";
import baseOC from "icfrontendbase";

// this component was created for the first deployment of iclibs
// because we need to sign out users that were already signed in before the iclib changes, 
// otherwise they will get errors because things like cloud account are not set in shared storage
// DONT use this component for actual authentication in the future
const SharedStorageAuthEnforementDecorator = ({ children }) => {
    useEffect(() => {
        const checkSession = async () => {
            const sharedStorage = baseOC.get("SharedStorage");
            const isSignedIn = await sharedStorage.get("auth_is_signed_in");
            if (isSignedIn === false) {
                signout();
            }
        }
        checkSession();
    }, []);

    return children;
}

export default SharedStorageAuthEnforementDecorator;