/* 
    used to store data in a "cache" storage, with custom value expiration logic

    args
        keyConfig   =   (object) see keyConfig.js for example.
                                 object of keys, each key has an object of 2 functions:
                                    some_key_name: { 
                                        getNewValue: async () => { return new value or null if error }, 
                                        isValueExpired: (value) => { return boolean }
                                    }
        storage     =   (class) see SessionStorage.js for example
                                a storage class with these methods: 
                                    get    = (key)        => { return value }
                                    put    = (key, value) => { set/replace a key's value, no return value } 
                                    delete = (key)        => { delete value but key should still return null, no return value }
        useStorage  =   (boolean) if true, CallStorage will try to get, put, delete key values from the storage
                                  if false, the storage will not be used. The getNewValue func will be used every time for getValue

    methods
        getValue    =   args:
                            key            = key to get value of
                            useStoredValue = (boolean) if true, returns stored value if it isnt expired. 
                                                       if false, does not check stored value, returns new value
        removeValue =   args:
                            key            = key to remove value
*/

export default class CallStorage {
    constructor(keyConfig, storage, useStorage=true) {
        this.keyConfig = keyConfig;
        this.storage = storage;
        this.useStorage = useStorage;
    }

    getValue = async (key, useStoredValue=true) => {
        const selectedKeyConfig = this.keyConfig[key];

        // if the current value is not expired (and we are using storage and the stored value)
        // then return current value
        if (this.useStorage === true && useStoredValue === true) {
            const curValue = this.storage.get(key);
            const isValueExpired = selectedKeyConfig.isValueExpired(curValue);
            if (isValueExpired === false) {
                return curValue;
            }
        }

        // return a new value, store it if we are using storage
        const newValue = await selectedKeyConfig.getNewValue();
        if (this.useStorage === true) {
            this.storage.put(key, newValue);
        }
        return newValue;
    }

    removeValue = (key) => {
        if (this.useStorage === true) {
            this.storage.delete(key);
        }
    }
}
