import React from "react";
import loader from "../../assets/images/favicon.svg";

const Progress = ({ className }) => (
  <div
    style={{
      position: "relative",
      left: 0,
      right: 0,
      display: "block",
      backgroundColor: "#fff",
      textAlign: "center",
      top: 0,
      bottom: 0,
    }}
  >
    <img
      src={loader}
      alt="loader"
      style={{ position: "absolute", top: "100px", zIndex: 100 , width : "100px", height : "100px"}}
    />
  </div>
);

export default Progress;
