import { SET_CLOUD_TYPE } from "constants/ActionTypes";

const init = null;

export default (state = init, action) => {
    if (action.type === SET_CLOUD_TYPE) {
        state = action.payload;
    }

    return state;
}