const init = { 
    needed: false,
    evalExceptionsAsOfAssessment: sessionStorage.getItem("evalExceptionsAsOfAssessment") ? (
        sessionStorage.getItem("evalExceptionsAsOfAssessment") === "true" ? true : false
    ) : false
};

const cloudAccount = (state = init, action) => {
    switch (action.type) {
        case 'neededTrue':
            return { ...state, needed: true }
        case 'neededFalse':
            return { ...state, needed: false }
        case "SET_EVAL_EXCEPTIONS_AS_OF_ASSESSMENT":
            return { ...state, evalExceptionsAsOfAssessment: action.payload }
        default: 
            return state;
    }
}

export default cloudAccount;