import React, { Component } from "react";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import IntlMessages from "util/IntlMessages";
import axios from "axios";
import Background from "assets/images/mission2.png";
import { handleGeneralErrorMessage } from "../util/functions";
import { getWhiteLabelPartnerName, getWhiteLabelLogoUrl } from "util/whitelabel";

const FormItem = Form.Item;

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validEmail: null,
      loading:false
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({loading:true})
        this.forgotPassword(values.email);
      }
    });
  };

  async forgotPassword(email) {
    try {
      let res = await axios({
        method: "post",
        headers: {
          "content-type": "application/json"
        },
        url: "/backend/account/forgotPassword",
        data: { email: email }
      });
      if (res && res.status !== "success") {
        this.setState({ validEmail: false });
      } else {
        this.setState({ validEmail: true });
      }
      this.setState({loading:false})
    } catch (err) {
      this.setState({loading:false})
      handleGeneralErrorMessage(err);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { validEmail } = this.state;

    return (
      <div
        className="gx-login-container"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundPosition: "right",

          backgroundRepeat: "no-repeat"
        }}
      >
        {validEmail === null ? (
          <div className="gx-login-content">
            <div className="gx-login-header">
              <img
                src={getWhiteLabelLogoUrl()}
                alt={getWhiteLabelPartnerName()}
                title={getWhiteLabelPartnerName()}
              />
            </div>
            <div className="gx-mb-4">
              <h2>Forgot Your Password ?</h2>
              <p>
                <IntlMessages id="app.userAuth.forgot" />
              </p>
            </div>

            <Form
              layout="vertical"
              onSubmit={this.handleSubmit}
              className="gx-login-form gx-form-row0"
            >
              <FormItem>
                {getFieldDecorator("email", {
                  rules: [
                    {
                      type: "email",
                      message: "The input is not a valid email"
                    },
                    {
                      required: true,
                      message: "Please input your email"
                    }
                  ]
                })(<Input type="email" placeholder="Email Address" />)}
              </FormItem>

              <FormItem>
                <Button type="primary" htmlType="submit" loading={this.state.loading}>
                  <IntlMessages id="app.userAuth.send" />
                </Button>
              </FormItem>
            </Form>
          </div>
        ) : validEmail === true ? (
          <div className="gx-login-content">
            <div className="gx-login-header">
              <img
                src={getWhiteLabelLogoUrl()}
                alt={getWhiteLabelPartnerName()}
                title={getWhiteLabelPartnerName()}
              />
            </div>
            <div className="gx-mb-4">
              <h3>
                {" "}
                Please check your Inbox or Spam folder for the password reset
                link email.
              </h3>
              <p></p>
            </div>
          </div>
        ) : (
          <div className="gx-login-content">
            <div className="gx-login-header">
              <img
                src={getWhiteLabelLogoUrl()}
                alt={getWhiteLabelPartnerName()}
                title={getWhiteLabelPartnerName()}
              />
            </div>
            <div className="gx-mb-4">
              <h3 style={{ lineHeight: "1.5" }}>
                {" "}
                If your email id is associated with us, an email will be sent to
                you shortly with instructions on how to reset the password.
              </h3>
              <p></p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const WrappedForgotPasswordForm = Form.create()(ForgotPassword);

export default WrappedForgotPasswordForm;
