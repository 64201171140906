import axios from "axios";
import { signout } from "util/functions";
import { message_session_expired, displayErrorList } from "util/functions";

export default {
    setupInterceptors: store => {
        axios.interceptors.response.use(
            function (res) {                
                if (res.data.fail_type === "session_expired") {
                    signout();
                    if (res.config.url !== "/backend/account/loginSetProfile") {
                        message_session_expired()
                    }
                } else {
                    store.dispatch({ type: "LAST_REQUEST", payload: res.config.url });
                }
                return res;
            },
            function (error) {
                const code = error?.response?.data?.code;
                const contextCode = error?.response?.data?.context_code; 

                const freeTrialExpiredPagePath = "/free_trial_expired";             
                if (
                    code === "PRODUCT_STATE_RESTRICTION" && 
                    contextCode === "FREE_TRIAL_EXPIRED" &&
                    window.location.pathname !== freeTrialExpiredPagePath
                ) {
                    window.location.replace(freeTrialExpiredPagePath);
                }

                if (error.response && error.response.status === 440 &&
                    error.response.config.url !== "/backend/account/loginSetProfile" &&
                    error.response.config.url !== "/backend/partner/accountWhiteLabel") {
                    signout();
                    message_session_expired()
                } else if (error.response && error.response.data && error.response.data.message === "configFailure") {

                    window.location.replace("/systemfailure")

                } else if (error.response && error.response.data && error.response.data.isDetailedError) {
                    // not using becuase we shouldnt display messages directly from core
                    // displayErrorList(error.response.data.detail, error.response.data.code);
                }
                return Promise.reject(error);
            }
        );
    }
};
