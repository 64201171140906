import { useEffect, useState } from "react"; 
import baseOC from "icfrontendbase";
import { initGlobalState, loadMicroApp } from "qiankun";
import getQiankunMicroApps from "./getQiankunMicroApps";

/*
    this hook handles loading and unloading product components based on the current path
    why is this used?
        there was an issue in dev on firefox where qiankun wasn't working with react router
        product components were only loaded/unloaded on page refresh, not dynamically after changing pages with react router

    @param currentPath (string)  the active path of the app. like the location of react-router

    @return null
*/

const useMicroservice = (currentPath) => {
    // when each microapp is loaded, it is stored here by its name as the object key
    const [ loadedMicroApp, setLoadedMicroApp] = useState({});

    useEffect(() => {
        initQiankun();
        // on component unmount, unload the microapps
        return () => {
            unloadMicroAppNames(Object.keys(loadedMicroApp));
            setLoadedMicroApp({});
        }
    }, []);

    useEffect(() => {        
        loadMicroApps();
    }, [currentPath]);

    const initQiankun = async () => {
        // initialize the qiankun global storage (used by shared storage)
        const sharedStorageInitValue = baseOC.get("SharedStorageInitValue");
        const initValue = sharedStorageInitValue.getInitValue();
        const actions = initGlobalState({ ...initValue });  

        // set the config variables needed to use qiankun and the iclib
        const configStorage = baseOC.get("ConfigStorage");
        configStorage.set("qiankun_application", "webapp");
        configStorage.set("qiankun_method", {
          setGlobalState: actions.setGlobalState,
          onGlobalStateChange: actions.onGlobalStateChange,
          offGlobalStateChange: actions.offGlobalStateChange
        });
        configStorage.set("route_cloud_account_setup", "/account/cloudaccountsetup?type=aws");

        // subscribe the shared storage to all updates from qiankun storage
        const sharedStorageGlobalSubscribeStrategyQiankun = baseOC.get("SharedStorageGlobalSubscribeStrategyQiankun");
        sharedStorageGlobalSubscribeStrategyQiankun.startGlobalSubscribe();

        // set the init shared storage values
        // Note: this does not use the initSharedStorage class because the microservice was still using the original init value (even when set before anything)
        const sharedStorage = baseOC.get("SharedStorage");
        await sharedStorage.set("assessment_exception_type", "contemporary");
        await sharedStorage.set("route_cloud_account_setup", "/account/cloudaccountsetup");
        let newAddon = await sharedStorage.get("addon");
        Object.keys(newAddon).forEach(addonKey => {
            newAddon[addonKey].route_to_gain_access = "/account/addons";
        })
        await sharedStorage.set("addon", newAddon)

        const newMicroApps = await getQiankunMicroApps();
        configStorage.set("qiankun_micro_apps", newMicroApps);
    }

    const getMatchingMicroApps = async () => {
        /*
        determine which product components to show based on the current page path
        example: 
            3 microapps: "/site/about", "/site/help", and "/site"
            currentPath: "/site/about"
            results in the microapps: "/site/about" and "/site"
            because currentPath "/site/about" starts with "/site"
        */
        const configStorage = baseOC.get("ConfigStorage");
        const microApps = await configStorage.get("qiankun_micro_apps");
        const matchingMicroApps = microApps.filter(microApp => currentPath.startsWith(microApp.activeRule));
        return matchingMicroApps;
    }

    const loadMicroApps = async () => {
        const matchingMicroApps = await getMatchingMicroApps();

        // determine which currently loaded microapps should be unloaded (because they are not matching anymore)
        const matchingMicroAppNames = matchingMicroApps.map(microApp => microApp.name);
        const microAppsNamesToUnload = Object.keys(loadedMicroApp).filter(loadedMicroAppName => matchingMicroAppNames.includes(loadedMicroAppName) === false);
        unloadMicroAppNames(microAppsNamesToUnload);

        // load the matching micro apps
        let newLoadedMicroApp = {};
        matchingMicroApps.forEach((microApp) => {
            const { name, entry, container, props } = microApp;
            // if the microapp is already loaded,
            // then dont load it again
            if (loadedMicroApp[name]) {
                newLoadedMicroApp[name] = loadedMicroApp[name];
                return
            }
            newLoadedMicroApp[name] = loadMicroApp({ name, entry, container, props });
        });
        setLoadedMicroApp(newLoadedMicroApp);
    }

    // the unload function does not update state on purpose
    // so that it avoids weird reference race conditions in the load function
    // for now, its better than useEffect spaghetti
    const unloadMicroAppNames = (names) => {
        names.forEach(microAppName => {
            if (!loadedMicroApp[microAppName]) return;
            loadedMicroApp[microAppName].unmount();
        });
    }

    return null
}

// since this app uses react-router v4, we don't have a useLocation hook.
// and we need to use a class component for withRouter
// so this is an intermediary function component between the class comp and hook
const UseMicroserviceFuncComp = ({ currentPath }) => {
    useMicroservice(currentPath);
    return null;
}

export { 
    UseMicroserviceFuncComp,
    useMicroservice
};
export default useMicroservice;