import React from "react";
import windows_step4aImg from "../../../assets/images/deviceAgent/windows_step4a.png";
import windows_step4bImg from "../../../assets/images/deviceAgent/windows_step4b.png";
import windows_step4cImg from "../../../assets/images/deviceAgent/windows_step4c.png";
import windows_step4dImg from "../../../assets/images/deviceAgent/windows_step4d.png";
import DownloadButton from "./DownloadButton";

function WindowsGuide(props) {
    return (
        <>
            <h2>OSQuery installation for Windows</h2>
            <ol className="guide-ol">
                <li>
                    <p>
                        Download OSQuery from the download button below.
                        This links directly to the osquery installer. 
                        You may be prompted to save the file or it may start immediately downloading.
                    </p>
                    <DownloadButton 
                        link="https://pkg.osquery.io/windows/osquery-5.0.1.msi" 
                        text="Download OSQuery" 
                        showLoading={false}
                    />
                </li>
                <li>
                    <p>
                        Run the osquery installer you just downloaded. 
                        The installer will guide you through the installation step-by-step. 
                        Ensure the install location matches the picture below.
                    </p>
                    {/* <div className="guide-img-box">
                        <img src={windows_step4aImg} alt="osquery installer page 1" />
                    </div> */}
                    <div className="guide-img-box">
                        <img src={windows_step4bImg} alt="osquery installer page 2" />
                    </div>
                    {/* <div className="guide-img-box">
                        <img src={windows_step4cImg} alt="osquery installer page 3" />
                    </div>
                    <div className="guide-img-box">
                        <img src={windows_step4dImg} alt="osquery installer page 4" />
                    </div> */}
                    <p>
                        Please note:
                        <ul>
                            <li>You need to have administrator privileges to install osquery.</li>
                            <li>
                                <strong>You will still need to apply your employer's configuration to authorize updates from your device. </strong>
                                This is described in the next section.
                            </li>
                        </ul>
                    </p>
                </li>
            </ol>
            <h2>Applying your employer's configuration</h2>
            <ol className="guide-ol">
                <li>
                    <p>
                        Click the button below to download the osconfig installer, win-config.zip.                      
                        This links directly to the config zip file. 
                        You may be prompted to save the file or it may start immediately downloading.
                    </p>
                    <DownloadButton 
                        link="/backend/general/deviceManagement/download?os=win" 
                        text="Download win-config.zip" 
                        showLoading={true}
                    />
                </li>
                <li>
                    <p>
                        Unzip win-config.zip. 
                        This needs to be unzipped so that you can easily run an included .bat file as administrator.
                    </p>
                </li>
                <li>
                    <p>
                        Navigate to the unzipped win-config content, right click the osquery_process.bat file, and select Run as administrator
                    </p>
                    <p>
                        The .bat file should open a terminal window and perform the process that configures osquery and authorizes your device to send updates to your employer.  
                        Please take a look at the output of the terminal window before closing to ensure it was successful.
                    </p>
                </li>
            </ol> 
        </>
    );
}

export default WindowsGuide;
