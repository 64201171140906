import { Component } from 'react';
import { connect } from 'react-redux';
import { signout } from "util/functions";
import axios from 'axios';
import '../styles/pages/timeout-warning.css';

let initTimeoutDelay = 60 * 17; // delay in seconds before timeout dialog 
let initTimeoutDuration = 60 * 2; // how long to display dialog in seconds
let timeoutDelay = initTimeoutDelay; 
let timeoutDuration = initTimeoutDuration; 
let timeRemaining = timeoutDelay + timeoutDuration;
let lastDate = new Date();
let currentDate = new Date();

class TimeoutWarning extends Component {
    constructor(props){
        super(props);
        this.state = {
            timeRemainingText: ''
        }

        this.continue = this.continue.bind(this);
        this.timeout = this.timeout.bind(this);
        this.formatTimeRemaining = this.formatTimeRemaining.bind(this);
    }

    componentDidMount() {
        this.timeout();
        clearInterval(this.intervalID);
        this.intervalID = null;
    }

    componentDidUpdate(oldProps) {
        if(oldProps.URL !== this.props.URL && this.props.URL !== '/backend/constant/logout') {
            clearInterval(this.intervalID);
            this.intervalID = null;
            timeoutDelay = initTimeoutDelay;
            timeoutDuration = initTimeoutDuration;
            timeRemaining = timeoutDelay + timeoutDuration;
            lastDate = new Date();
            currentDate = new Date();
            this.timeout();
        }
        if(this.props.URL === '/backend/constant/logout') {
            clearInterval(this.intervalID);
            this.intervalID = null;
        }
    }

    continue = () => {
        axios.get("/backend/constant/test");
        clearInterval(this.intervalID);
        this.intervalID = null;
        timeoutDelay = initTimeoutDelay;
        timeoutDuration = initTimeoutDuration;
        timeRemaining = timeoutDelay + timeoutDuration;
        lastDate = new Date();
        currentDate = new Date();
        this.timeout();
        document.getElementById('timeout-warning-container-outer').classList.add('display-none-timeout');
        this.setState({ timeRemainingText: '' });
    }

    timeout = () => {
        return (
            this.intervalID = setInterval(function() { 
                currentDate = new Date();
                timeRemaining = Math.round((timeoutDelay + timeoutDuration) - ((currentDate - lastDate) / 1000));
                if(timeRemaining === timeoutDuration) {
                    if(document.getElementById('timeout-warning-container-outer')) {
                        document.getElementById('timeout-warning-container-outer').classList.remove('display-none-timeout');
                        let currentText = this.formatTimeRemaining(timeRemaining);
                        this.setState({ timeRemainingText: currentText });
                    } else {
                        clearInterval(this.intervalID);
                        this.intervalID = null;
                    }   
                } else if(timeRemaining < timeoutDuration && timeRemaining > 0) {  
                    if(document.getElementById('timeout-warning-container-outer')) {
                        if(document.getElementById('timeout-warning-container-outer').classList.contains('display-none-timeout')) {
                            document.getElementById('timeout-warning-container-outer').classList.remove('display-none-timeout');
                            let currentText = this.formatTimeRemaining(timeRemaining);
                            this.setState({ timeRemainingText: currentText });
                        } 
                    }
                    let currentText = this.formatTimeRemaining(timeRemaining);
                    this.setState({ timeRemainingText: currentText });
                } else if(timeRemaining < 1){
                    clearInterval(this.intervalID);
                    this.intervalID = null;
                    signout()
                }
            }.bind(this), 1000)
        );
    }

    formatTimeRemaining = timeRemaining => {
        let minutes = Math.floor(timeRemaining / 60);
        if(minutes === 0) { minutes = '' }
        else if(minutes === 1) { minutes = minutes + ' minute ' }
        else if(minutes > 1) { minutes = minutes + ' minutes ' }

        let seconds = timeRemaining % 60;
        if(seconds === 1) { seconds = seconds + ' second' }
        else if(seconds > 1) { seconds = seconds + ' seconds' }

        return minutes + seconds;
    }

    render() {
        let { timeRemainingText } = this.state;
        return (
            <div id='timeout-warning-container-outer' className='display-none-timeout'>
                <div id='timeout-warning-container-inner'>
                    <h2>Session Expiration Warning</h2>
                    <p>
                        Due to inactivity, your session will expire in {timeRemainingText}.<br/>
                        Do you want to extend the session?
                    </p>
                    <div>
                        <button onClick={this.continue}>Continue</button>
                        <button id='timeout-warning-logout' onClick={() => signout()}>Logout</button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ lastRequest }) => {
    const { URL } = lastRequest;
    return { URL };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeoutWarning);