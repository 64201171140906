import axios from "axios";
import moment from "moment";

// object of custom functions for each stored key

/*
    "key of the value to store": {
        getNewValue: async () => {
            an async function that returns the value to store
            if error or cant get the value, return null
        },
        isValueExpired: (value) => {
            a function with an argument of the current stored value
            return true if the value is expired
            return false if not expired
        }
    }
*/

export const keyConfig = {
    "/backend/data/assessmentMeta": {
        getNewValue: async () => {
            try {
                const res = await axios.get("/backend/data/assessmentMeta")
                return res.data;
            } catch (error) {
                return null;
            }
        },
        isValueExpired: (value) => {
            try {
                // if the value doesnt exist
                if (!value) return true;

                // if the current time is past the scheduled scan start time
                // if the scan is running
                const currentUtcTime = moment().toISOString();
                if (currentUtcTime > value.scheduled_ingestion_dt || value.progress_state !== "scheduled_for_ingestion") {
                    return true;
                }
                return false;

            } catch (error) {
                return true;
            }
        }
    }
}