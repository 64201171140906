import React, { Component } from "react";
import Popover from "antd/es/popover";
import { Link } from "react-router-dom";
import { signout } from "util/functions";

class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible : false
    }

    this.hide = this.hide.bind(this);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
  }

  hide = () => {
    this.setState({ visible: false });
  }

  handleVisibleChange = visible => {
    this.setState({ visible });
  }

  render() {
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li onClick = {() => this.hide()}> <Link onClick = {() => this.hide()} to="/account/usersettings/profile">User Settings</Link> </li>
       
        <li onClick={() => signout()}>Logout
        </li>
      </ul>
    );

    return (
      <Popover 
        overlayClassName="gx-popover-horizantal" 
        placement="bottomRight" 
        content={userMenuOptions}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
      >
       <i className="fas fa-user-circle" style={{ fontSize :'20px' , color: 'DodgerBlue'}} /> {" "}
      </Popover>
    )
  }
}

export default UserInfo;
