import React from "react";

// import "antd/dist/antd.css";
import "../styles/pages/systemFailure.css";


class SystemFailure extends React.Component {
  state = {
    applicationId: "",
    applicationDetails: {},
    loading: true,
  };
  componentDidMount = () => {
    this.setState({ loading: false });
  };
  render() {
    return (
      <div className="systemfailure-container">
   
        <h2 style={{ color : "red"}}>System Failure</h2>
        <h3 style={{ color : "red"}}>
	The system is currently not available. Please try back later.
        </h3>
      </div>
    );
  }
}
export default SystemFailure;