import React, { useState } from "react";
import { copyTextToClipboard } from "../../util/functions";

function CodeBlockStyled(props) {
    // props.size accepts 2 different parameters
        // fill - expands to fit the parent container width
        // sm - similar in appearance to fill but only fits content with some padding, and no copy
        // inline - uses the min height and width needed and can be used inline with text

    const [buttonText, setButtonText] = useState("Copy to Clipboard")
    const handleCopyClick = text => {
        copyTextToClipboard(text);
        setButtonText("Copied!")
    }

    // in future could add feature for size inline copy
    return (
        <div className={`code-block code-block-type-${props.size}`}>
            {props.size === "fill" ?
                <button onClick={() => handleCopyClick(props.text)}>
                    <i className="far fa-copy"/> {buttonText}
                </button>
            : null}
            <pre>
                <code>
                    {props.text}
                </code>
            </pre>
        </div>
        
    );
}

export default CodeBlockStyled;
