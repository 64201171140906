import React from "react";
import { hot } from "react-hot-loader";
import { ConnectedRouter } from "react-router-redux";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import NetworkService from "./util/NetworkService";
import "assets/vendors/style";
import "styles/wieldy.less";
import configureStore, { history } from "./appRedux/store";
import App from "./containers/App/index";
import AppWhitelabelProvider from "./components/AppWhitelabelProvider";


export const store = configureStore();
NetworkService.setupInterceptors(store);
const NextApp = () => (
  <Provider store={store}>
    <AppWhitelabelProvider>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </ConnectedRouter>
    </AppWhitelabelProvider>
   </Provider>
);

export default hot(module)(NextApp)
