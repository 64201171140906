import React from "react";
import CodeBlockStyled from "../CodeBlockStyled";
import linux_step2Img from "../../../assets/images/deviceAgent/linux_step2.png";

const debianCmdExample = 
`export OSQUERY_KEY=1484120AC4E9F8A1A577AEEE97A80C63C9D8B80B
apt-key adv --keyserver hkp://keyserver.ubuntu.com:80 --recv-keys $OSQUERY_KEY
add-apt-repository 'deb [arch=amd64] https://pkg.osquery.io/deb deb main'
apt-get update -y
apt-get install osquery -y`

const configFileText = 
`{
    // Configure the daemon below:
    "options": {
        // Select the osquery config plugin.
        "config_plugin": "filesystem",
        // Select the osquery logging plugin.
        "logger_plugin": "aws_firehose",
        "aws_firehose_stream": "osquerytest",
        "aws_access_key_id": "AKIAWGPL5YQCC6CKD6O2",
        "aws_secret_access_key": "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",
        "aws_region": "us-east-1",
        "utc": "true"
    },
    // Define a schedule of queries:
    "schedule": {
        // windows security Info
        "windows_security_info": {
            "query": "SELECT * from system_info;",
            // The interval in seconds to run this query, not an exact interval.
            "interval": 60
        }
    },
    // Decorators are normal queries that append data to every query.
    "decorators": {
        "load": [
            "SELECT uuid AS host_uuid FROM system_info;",
            "SELECT user AS username FROM logged_in_users ORDER BY time DESC LIMIT 1;",
            "SELECT 'AKIAWGPL5YQCC6CKD6O2' as AccessKey FROM system_info;",
            "SELECT '426233414660' as AccountID FROM system_info;"
        ]
    },
    "packs": {
        // "iCompaas-compliance": "C:\\Program Files\\osquery\\packs\\iCompaas-compliance-wnds.conf"
    }
}`


function LinuxGuide() {
    return (
        <>
            <h2>Installation for Linux users</h2>
            <ol className="guide-ol">
                <li>
                    <p>
                        Please install the osquery package from this link&nbsp;
                        <a href="https://osquery.io/downloads/official/5.0.1" target="_blank" rel="noopener noreferrer">
                            https://osquery.io/downloads/official/5.0.1 <i className="fas fa-external-link-alt"></i>
                        </a>
                    </p>
                </li>
                <li>
                    <p>
                        Select the version of Linux that is relevant to you.
                        <br/>
                        <strong>Note:</strong> The package at the time of the creation of this guide was 5.0.1 but it could be different at the time you are installing. 
                        This picture is for guidance purposes only.
                    </p>
                    <div className="guide-img-box">
                        <img src={linux_step2Img} alt="linux os query package download"/>
                    </div>
                    <p style={{marginBottom:"10px"}}>
                        <a href="https://osquery.readthedocs.io/en/stable/installation/install-linux/#running-osquery" target="_blank" rel="noopener noreferrer">Installation Details <i className="fas fa-external-link-alt"></i></a>
                        <br/>
                        <a href="https://en.wikipedia.org/wiki/List_of_Linux_distributions" target="_blank" rel="noopener noreferrer">Linux Distribution List <i className="fas fa-external-link-alt"></i></a>
                    </p>
                    <p>
                        <strong>Example for Debian Distribution - Ubuntu</strong>
                    </p>
                    <CodeBlockStyled text={debianCmdExample} size="inline" />
                </li>
                <li>
                    <p>Create a file named osquery.conf with this content:</p>
                    <CodeBlockStyled text={configFileText} size="fill" />
                </li>
                <li>
                    <p>Copy the conf file to the /etc/osquery/ folder</p>
                    <CodeBlockStyled text={`cp osquery.conf /etc/osquery/osquery.conf`} size="inline"/>
                </li>
            </ol>
        </>
    );
}

export default LinuxGuide;
