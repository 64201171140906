import React, { useState, useEffect } from "react";
import axios from "axios";
import baseOC from "icfrontendbase";

const sharedStorage = baseOC.get("SharedStorage");

const AppWhitelabelProvider = ({ children }) => {
    const [ showLoading, setShowLoading ] = useState(true);

    useEffect(() => {
        getAppWhiteLabel();
    }, []);

    const setPageTitle = (title) => {
        document.title = title;
    }

    const setFavicon = (faviconUrl) => {
        let favicon = document.createElement("link");
        favicon.rel = "shortcut icon";
        favicon.href = faviconUrl;
        document.head.appendChild(favicon);
    }

    const getAppWhiteLabel = async () => {
        try {
            // get the domain without subdomain
            const hostname = location.hostname; 
            let parts = hostname.split('.');  
            if (parts.length > 2) {
                parts.shift();
            }
            const domain = parts.join('.');

            // get the whitelabel data by domain
            const appWhiteLabelRes = await axios.get(`/backend/partner/appWhiteLabel?domain=${domain}`);
            const appWhiteLabel = appWhiteLabelRes.data;

            // if whitelabel styling is provided
            // then apply the css styles 
            if (appWhiteLabel.style) {
                const styleEl = document.createElement("style");
                styleEl.innerHTML = appWhiteLabel.style;
                document.head.appendChild(styleEl);
            }

            // if a whitelabel field is provided
            // then set it to override the default value
            const whiteLabelFields = [
                "logo_url",
                "favicon_url",
                "partner_name",
                "safe_partner_name",
                "copyright_name",
                "support_email",
                "support_meeting_link",
                // "main_website_url_base" // temporarily unused until the main website is whitelabelled
            ];
            whiteLabelFields.forEach(field => {
                if (appWhiteLabel[field]) {
                    nonPersistentStorageObject.app_white_label[field] = appWhiteLabel[field];
                }
            });
            nonPersistentStorageObject.app_white_label.is_partner = true;
            setPageTitle(document.title = nonPersistentStorageObject.app_white_label.partner_name + " - Admin Dashboard");
            setFavicon(nonPersistentStorageObject.app_white_label.favicon_url);

            // set shared storage whitelabel values
            // if a whitelabel field value is unset, then use the default icompaas value
            sharedStorage.set("whitelabel", {
                name: appWhiteLabel.partner_name || nonPersistentStorageObject.app_white_label.partner_name,
                copyright_name: appWhiteLabel.copyright_name || nonPersistentStorageObject.app_white_label.copyright_name,
                safe_name: appWhiteLabel.safe_partner_name || nonPersistentStorageObject.app_white_label.safe_partner_name,
                logo_url: appWhiteLabel.logo_url || nonPersistentStorageObject.app_white_label.logo_url,
                customer_support_contact: {
                    email: appWhiteLabel.support_email || nonPersistentStorageObject.app_white_label.support_email,
                },
                feature_toggle: {
                    assessment_check_remediation_link: false
                }
            })
            
            setShowLoading(false);
        } catch(error) {
            // set the page title to the default whitelabel
            nonPersistentStorageObject.app_white_label.is_partner = false;
            setPageTitle(nonPersistentStorageObject.app_white_label.partner_name + " - Admin Dashboard");
            setFavicon(nonPersistentStorageObject.app_white_label.favicon_url);

            setShowLoading(false);
        }
    }

    if (showLoading) {
        return (
            <div className="loader loading">
                <img src="/favicon.svg" alt="loader" />
            </div>
        )
    }

    return children;
}

export default AppWhitelabelProvider;