import {combineReducers} from "redux";
import {routerReducer} from "react-router-redux";
import Settings from "./Settings";

import Auth from "./Auth";
import Notes from "./Notes";
import Contact from "./Contact";
import Common from "./Common";
import CloudAccount from "./CloudAccount";
import CloudAccountId from "./CloudAccountId";
import CloudAction from "./CloudAction";
import CloudType from "./CloudType";
import PlanId from "./PlanId";
import PlanDetails from "./PlanDetails";
import LastRequest from "./LastRequest";
import Soc1 from "./Soc1";



const reducers = combineReducers({
  routing: routerReducer,
  settings: Settings,
  auth: Auth,
  notes: Notes,
  contact: Contact,
  common: Common,
  CloudAccount: CloudAccount,
  cloudAccountId: CloudAccountId,
  cloudAction: CloudAction,
  cloudType: CloudType,
  PlanId: PlanId,
  PlanDetails: PlanDetails,
  lastRequest: LastRequest,
  Soc1: Soc1
});

export default reducers;
