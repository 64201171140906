import React from "react";
import { Icon } from "antd";
import "./severityMessage.css";


/*


Left off here

Making a base class for error messages, severity specific styling goes in severitymessage.css now.

Make the error, info, warning comps use this base class

NoCloudAccount, NoDataMessage use the warning comps

Make it so title is optional and the body/children dont have padding when not used

*/



const SeverityMessage = ({ title=null, severity, children, ...rest }) => {

    const severityIconTypeMap = {
        note: null,
        info: "info-circle",
        warning: "warning",
        error: "exclamation-circle"
    }

    return (
        <div className={`severity-message ${severity}-message`} {...rest}>
            {title ? (
                <div className="severity-message-title-row">
                    {severityIconTypeMap?.[severity] && (
                        <Icon type={severityIconTypeMap[severity]} className="severity-title-icon" />
                    )}
                    <h4 className="severity-message-title">{title}</h4>
                </div>
            ) : null}
            
            {children ? (
                <div className={title ? "severity-message-body" : null}>
                    {children}
                </div>
            ) : null}
        </div>
    )
}

export default SeverityMessage;