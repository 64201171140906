import React, { Component } from "react";
import Layout from "antd/es/layout";
import CloudAccountSelector from "../CloudAccountSelector";
import TimeoutWarning from "../../util/TimeoutWarning";
import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";
import { switchLanguage, toggleCollapsedSideNav } from "../../appRedux/actions/Setting";
import UserInfo from "components/UserInfo";
import Auxiliary from "util/Auxiliary";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE
} from "../../constants/ThemeSetting";
import { connect } from "react-redux";
import "styles/pages/topbar.css";
import UserProfile from "../Sidebar/UserProfile";
import "styles/pages/breadcrumb.css";
import { NavLink, useLocation, withRouter } from "react-router-dom";
import HipaaImage from "../../assets/images/HIPAA.jpeg";
import CisImage from "../../assets/images/CIS.jpg";
import Soc1Image from "../../assets/images/soc15.png";
import Soc2Image from "../../assets/images/soc2/soc25.png";
import IsoImage from "../../assets/images/ISO27001.jpg";
import NIST171Image from "../../assets/images/nist171.png";
import NIST853Image from "../../assets/images/nist853.png";
import AwsWaImage from "../../assets/images/AWS_WA_ICON_2_BLUE.png";
import PentestImage from "../../assets/images/pen/webapp_pen_test.jpg";
import axios from "axios";
import SecurityTabs from 'components/SecurityTabs';
const { Header } = Layout;

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_type: null,
      searchText: "",
      windowWidth: window.innerWidth,
      currentPath: window.location.pathname,
      purchasedAddons: []
    };
  }

  languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={e => this.props.switchLanguage(language)}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  )

  updateSearchChatUser = evt => {
    this.setState({
      searchText: evt.target.value
    });
  }

  async componentDidMount() {
    window.addEventListener('resize',
      () => this.setState({ windowWidth: window.innerWidth })
    );

    // Update currentPath when location changes
    this.unlisten = this.props.history.listen((location) => {
      this.setState({ currentPath: location.pathname });
    });

    this.fetchPurchasedAddons();

    // Handle initial path for security tabs
    const currentPath = window.location.pathname;
    if (currentPath.includes('/portal/compliance/cislevel1') || 
        currentPath.includes('/portal/compliance/cislevel2') || 
        currentPath.includes('/portal/compliance/awswellarch') ||
        currentPath.includes('/portal/compliance/security/')) {
      
      this.props.updateSidebarKeys(['security']);
      
      this.props.dispatch({
        type: 'SET_MENU_STATE',
        payload: {
          openKeys: ['security'],
          selectedKeys: [currentPath.replace('/portal/', '')]
        }
      });
    }

    try {
      const response = await axios.get("/backend/constant/getUserType");
      this.setState({ user_type: response.data });
    } catch (error) {
      console.error("Error fetching user type:", error);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setState({ windowWidth: window.innerWidth }));
    // Clean up the history listener
    if (this.unlisten) {
      this.unlisten();
    }
    // Cancel any pending state updates
    this.setState = () => {};
    // Clear any timeouts or intervals if you have any
  }

  handleTabClick = (path) => {
    this.setState({ currentPath: path });

    if (this.props.navCollapsed) {
      this.props.toggleCollapsedSideNav(false);
    }

    // Updated condition to handle all security-related paths
    if (path.includes('/portal/compliance/cislevel1') || 
        path.includes('/portal/compliance/cislevel2') || 
        path.includes('/portal/compliance/awswellarch') ||
        path.includes('/portal/compliance/security/')) {
      
      this.props.updateSidebarKeys(['security']);
      
      this.props.dispatch({
        type: 'SET_MENU_STATE',
        payload: {
          openKeys: ['security'],
          selectedKeys: [path.replace('/portal/', '')]
        }
      });
    } else if (path.includes('/portal/compliance/')) {
      this.props.updateSidebarKeys(['compliance']);
      
      this.props.dispatch({
        type: 'SET_MENU_STATE',
        payload: {
          openKeys: ['compliance'],
          selectedKeys: [path.replace('/portal/', '')]
        }
      });
    }
  }

  fetchPurchasedAddons = async () => {
    try {
      const response = await axios.get("/backend/billing/details");
      if (response.data && response.data.length > 0) {
        const processedAddons = response.data.map(addon => {
          // Special handling for CIS levels
          if (addon.feature_id === 'cis') {
            return [{
              ...addon,
              feature_id: 'cislevel1',
              original_feature_id: 'cislevel1'
            }, {
              ...addon,
              feature_id: 'cislevel2',
              original_feature_id: 'cislevel2'
            }];
          }
          // Handle pentest case
          if (addon.feature_id === 'pentest') {
            return {
              ...addon,
              feature_id: 'pen_test'
            };
          }
          return addon;
        });

        // Flatten the array since CIS creates nested arrays
        const flattenedAddons = processedAddons.flat();
        this.setState({ purchasedAddons: flattenedAddons });
      }
    } catch (error) {
      console.error("Error fetching purchased addons:", error);
    }
  }

  // Add this method to check for available unpurchased addons
  hasUnpurchasedAddons = (allTabs, purchasedTabs) => {
    return allTabs.length > purchasedTabs.length;
  };

  render() {
    const { navCollapsed, navStyle, cloudType } = this.props;
    const { windowWidth, currentPath, purchasedAddons, user_type } = this.state;

    let addonTabs = [
      {
        id: "soc2",
        title: "SOC2 Readiness",
        path: "/portal/compliance/soc2",
        icon: Soc2Image
      },
      // {
      //   id: "pen_test",
      //   title: "WebApp Scanner",
      //   path: "/portal/compliance/pentest",
      //   icon: PentestImage
      // },
      {
        id: "hipaa",
        title: "HIPAA Readiness",
        path: "/portal/compliance/hipaa",
        icon: HipaaImage
      },
      {
        id: "iso27001",
        title: "ISO27001 Readiness",
        path: "/portal/compliance/iso27001",
        icon: IsoImage
      }
    ];

    // Only add NIST tabs if cloudType is not azure
    if (cloudType !== 'azure') {
      addonTabs = [
        ...addonTabs,
        {
          id: "nist171",
          title: "NIST 800-171 Readiness",
          path: "/portal/compliance/nist171",
          icon: NIST171Image
        },
        {
          id: "nist853",
          title: "NIST 800-53 Readiness",
          path: "/portal/compliance/nist853",
          icon: NIST853Image
        }
      ];
    }

    // Modified filter to correctly handle CIS levels and WebApp Scanner
    const purchasedAddonTabs = addonTabs.filter(tab => {
      return this.state.purchasedAddons.some(addon => {
        // For CIS tabs
        if (tab.id === 'cis') {
          return addon.feature_id === tab.level ||
            (addon.feature_id === 'cis' && (tab.level === 'cislevel1' || tab.level === 'cislevel2'));
        }
        // For WebApp Scanner
        if (tab.id === 'pen_test') {
          return addon.feature_id === 'pen_test' || addon.feature_id === 'pentest';
        }
        // For all other addons
        return addon.feature_id === tab.id;
      });
    });

    return (
      <Auxiliary>
        <Header className="no-wrap">
          {navStyle === NAV_STYLE_DRAWER ||
            ((navStyle === NAV_STYLE_FIXED ||
              navStyle === NAV_STYLE_MINI_SIDEBAR) &&
              windowWidth < TAB_SIZE) ? (
            <div className="gx-linebar d-flex justify-content-center align-items-center" >
              <i
                className="gx-icon-btn icon icon-menu "
                onClick={() => {
                  this.props.toggleCollapsedSideNav(!navCollapsed);
                }}
              />
            </div>
          ) : null}
          {window.innerWidth < "400" ?
            <CloudAccountSelector width="280px" style={{ display: "inline-block" }} />
            :
            <CloudAccountSelector width="85%" style={{ display: "inline-block" }} />
          }
          <ul style={{ display: "inline-block" }} className="gx-header-notifications gx-ml-auto ">
            {windowWidth >= TAB_SIZE ? null : (
              <Auxiliary>
                <li className="gx-user-nav">
                  <UserInfo />
                </li>
              </Auxiliary>
            )}
          </ul>
          <div style={{ marginTop: '20px' }}>
            {windowWidth >= TAB_SIZE && <UserProfile />}

          </div>
        </Header>
        <TimeoutWarning />
        {currentPath.includes('/portal/compliance/security') || 
         (currentPath.includes('/portal/compliance/cislevel1') || 
          currentPath.includes('/portal/compliance/cislevel2') || 
          currentPath.includes('/portal/compliance/awswellarch')) && cloudType === "aws" ? (
          <SecurityTabs 
            currentPath={currentPath}
            purchasedAddons={purchasedAddons}
            handleTabClick={this.handleTabClick}
            cloudType={this.props.cloudType}
          />
        ) : (
          <div className="fancy-tab-container font-poppins">
            {purchasedAddonTabs.length > 0 ? (
              <div className="fancy-tabs">
                {purchasedAddonTabs.map((menu) => (
                  <div
                    className={`tab-item ${currentPath === menu.path ? 'active' : ''}`}
                    key={menu.title}
                  >
                    <NavLink
                      to={menu.path}
                      className="tab-link"
                      onClick={() => this.handleTabClick(menu.path)}
                    >
                      {/* <span className="tab-icon"> */}
                        {/* <span className="checkmark-icon mr-1 ml-1">
                          <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3">
                            <path d="M5 13l4 4L19 7" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </span> */}
                      {/* </span> */}
                      <img
                        src={menu.icon}
                        alt={menu.title}
                        className="custom-tab-icon"
                        style={{ width: '28px', height: '28px' }}
                      />
                      <span className="tab-title md:text-[16px]">{menu.title}</span>
                    </NavLink>
                  </div>
                ))}

                {/* Add More Addons tab if there are unpurchased addons */}
                {this.hasUnpurchasedAddons(addonTabs, purchasedAddonTabs) && user_type !== null && typeof user_type !== "undefined" && user_type !== "subuser" && (
                  <div className="tab-item">
                    <NavLink
                      to="/account/addons"
                      className="tab-link hover:from-blue-100 hover:to-blue-200"
                    >
                      <span className="tab-icon">
                        <svg
                          className="w-7 h-7 text-blue-500"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                          />
                        </svg>
                      </span>
                      <span className="tab-title md:text-[16px] font-medium">
                        More Addons
                      </span>
                    </NavLink>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex items-center justify-center w-full p-4 bg-blue-50 text-blue-700">
                <div className="text-center">
                  <p className="mb-2">No compliance modules are currently active.</p>
                  <NavLink
                    to="/account/addons"
                    className="inline-flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-700 transition-colors"
                  >
                    <span>Explore Available Modules</span>
                    <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                  </NavLink>
                </div>
              </div>
            )}
          </div>
        )}


      </Auxiliary>
    );
  }
}

const mapStateToProps = ({ settings, PlanId, PlanDetails, cloudType }) => {
  const { navStyle, themeType, locale, pathname, navOpen, sidebarKeys, menuState } = settings;
  const { name } = PlanId;
  const { details } = PlanDetails;
  return {
    navStyle,
    themeType,
    locale,
    pathname,
    name,
    details,
    navOpen,
    cloudType,
    sidebarKeys,
    settings
  };
};

const mapDispatchToProps = dispatch => ({
  toggleCollapsedSideNav: (val) => dispatch(toggleCollapsedSideNav(val)),
  switchLanguage: (language) => dispatch(switchLanguage(language)),
  updateSidebarKeys: (keys) => dispatch({
    type: 'UPDATE_SIDEBAR_KEYS',
    payload: keys
  }),
  dispatch
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Topbar));
