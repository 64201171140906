import { SET_CLOUD_ACCOUNT_ID } from "constants/ActionTypes";

const init = null;

export default (state = init, action) => {
    if (action.type === SET_CLOUD_ACCOUNT_ID) {
        state = action.payload;
    }

    return state;
}