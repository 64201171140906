import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { UseMicroserviceFuncComp } from "./useMicroservice";
import SharedStorageAuthEnforementDecorator from "./SharedStorageAuthEnforementDecorator";
import Portal from "./portal/index";
import Account from "./account/index";
import "styles/layout/routes.css";
import "styles/layout/icfrontendui-legacy-overrides.css";

class App extends Component {
  constructor() {
    super();
  }

  render() {
    const { match, location } = this.props;
    return (
      <SharedStorageAuthEnforementDecorator>
        <div style={{flex:"1 0"}}>
          <div id="portal-product-component" />
          {/* <div id="portal-product-component2" /> */}
          <UseMicroserviceFuncComp currentPath={location.pathname} />
          <Switch>
            <Route path={`${match.url}portal`} component={Portal} />
            <Route path={`${match.url}account`} component={Account} />
          </Switch>
        </div>
      </SharedStorageAuthEnforementDecorator>
    )
  }
}

export default withRouter(App);
