const init = { details: '' };

const PlanDetails = (state = init, action) => {
    if(action.type === 'PlanDetails'){
        state = { details: action.payload };
    }
    else if (action.type === 'PlanDetailsClear') {
        state = { details: '' };
    }
    return state;
}

export default PlanDetails;