import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  onNavStyleChange,
  toggleCollapsedSideNav,
  onNavToggle

} from "appRedux/actions/Setting";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import { getWhiteLabelPartnerName, getWhiteLabelLogoUrl } from "util/whitelabel";

class SidebarLogo extends Component {

  render() {

    const { themeType, navCollapsed } = this.props;

    const width = window.innerWidth;
    let { navStyle } = this.props;
    if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
      navStyle = NAV_STYLE_DRAWER;
    }

    return (
      <div className="gx-layout-sider-header">
        {navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR ? (
          <div className="gx-linebar">
            <i
              className={`gx-icon-btn icon icon-${
                navStyle === NAV_STYLE_MINI_SIDEBAR
                  ? "menu-unfold"
                  : "menu-fold"
              } ${themeType !== THEME_TYPE_LITE ? "gx-text-white" : ""}`}
              onClick={() => {
                if (navStyle === NAV_STYLE_DRAWER) {
                  this.props.toggleCollapsedSideNav(!navCollapsed);
                } else if (navStyle === NAV_STYLE_FIXED) {
                  this.props.onNavStyleChange(NAV_STYLE_MINI_SIDEBAR);
                  if (this.props.windowWidth >= TAB_SIZE) {
                    this.props.onNavToggle(false);
                  }
                } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
                  this.props.toggleCollapsedSideNav(!navCollapsed);
                } else {
                  this.props.onNavStyleChange(NAV_STYLE_FIXED);
                  if (this.props.windowWidth >= TAB_SIZE) {
                    this.props.onNavToggle(true);
                  }
                }
              }}
            />
          </div>
        ) : null}

        <Link to="/" className="gx-site-logo">
          {navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR &&
          width >= TAB_SIZE ? null : themeType === THEME_TYPE_LITE ? (
            <img
              alt={getWhiteLabelPartnerName()}
              object-fit="cover"
              width="150"
              height="50"
              id="site-logo"
              src={getWhiteLabelLogoUrl()}
              ref={img => (this.img = img)}
            />
          ) : (
            <img alt="" src={getWhiteLabelLogoUrl()} />
          )}
        </Link>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { navStyle, themeType, width, navCollapsed,navOpen } = settings;
  return { navStyle, themeType, width, navCollapsed,navOpen };
};

export default connect(mapStateToProps, {
  onNavStyleChange,
  toggleCollapsedSideNav,
  onNavToggle
})(SidebarLogo);
