import React from "react";
import { Route, Switch } from "react-router-dom";
import Install from "./install";
import "../../styles/pages/deviceAgent.css";

const Main = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/install`} component={Install}/>
  </Switch>
);

export default Main;