import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const AuditorManagementRoute = ({match}) => (
  <Switch>
    <Route path={`${match.url}/`} component={asyncComponent(() => import('./src/index'))}/>
  </Switch>
);

export default AuditorManagementRoute;