import React from "react";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Background from "assets/images/mission2.png";
import IntlMessages from "util/IntlMessages";
import axios from "axios";
import "text-security/dist/text-security.css";
import "styles/pages/subUserActivation.css";
import Progress from "components/Progress";
import { getWhiteLabelPartnerName, getWhiteLabelSupportEmail, getWhiteLabelLogoUrl } from "util/whitelabel";
import "styles/free_tier.css";

const FormItem = Form.Item;

class SubUserActivation extends React.Component {
  constructor() {
    super();
    this.state = {
      loader: true,
      first_name: "",
      last_name: "",
      title: "",
      password: "",
      confirmPassword: "",
      activation_code: null,
      email_hash: null,
      phone_number: "",
      validated: null,
      user_id: null,
      showMessage: false,
      message: "",
      email: ""
    };
  }

  handleSubmit = async e => {
    var { user_id, activation_code, email_hash } = this.state;
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err && values) {
      
         await axios({
            method: 'post',
            url: '/backend/account/activation',
            data: {
              user: {
                user_id: user_id,
                activation_code: activation_code,
                email_hash: email_hash,
                title: values.title,
                first_name: values.first_name,
                last_name: values.last_name,
                phone_number: values.phone_number,
                password: values.password
              }
            }
          }).then( res =>  {
            if(res.status === 400 || res.status === 500 ){
              this.setState({
                showMessage: true,
                message: res.data && res.data.message
              }); 
            } else {
              this.setState({
                showMessage: true,
                message: (
                  <span>
                    Your Account is activated!! <br />
                    Please proceed to <a href="/signin"> Signin </a>
                  </span>
                )
              });
            }
            
          }). catch(error => {
            this.setState({
              showMessage: true,
              message: (
              <span style={{  color : "red"}}> 
                {error.response.data.message}
                Please proceed to <a href="/signin"> Signin </a>
              </span>
            )
            });
          
        })
        
          
      }
    });
  }

  handleInputChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  }

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  }

  passwordValidator = (rule, value, callback) => {
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_-])(?=.{8,})"
    );
    if (value !== null && !strongRegex.test(value)) {
      return callback(
        "Password should contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character from(!@#$%^&*_-)"
      );
    } else return callback();
  }

  async componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    let activation_code = urlParams.get("activation_code");
    let email_hash = urlParams.get("email_hash");
    if (
      activation_code !== null &&
      typeof activation_code !== "undefined" &&
      email_hash !== null &&
      typeof email_hash !== "undefined"
    ) {
      await this.validateSubuserActivationLink(
        activation_code,
        email_hash
      ).then(result => {
        setTimeout(() => {
          this.setState({ loader: false });
        }, 500);

        if (
          result &&
          typeof result !== "undefined" &&
          result.status !== "fail" &&
          result.users &&
          result.users.length > 0
        ) {
          this.setState({
            user_id:
              result.users[0] &&
              result.users[0].user &&
              result.users[0].user.user_id,
            validated: true,
            activation_code: activation_code,
            email_hash: email_hash,
            email:
              result.users[0] &&
              result.users[0].user &&
              result.users[0].user.email
          });
        } else {
          this.setState({
            validated: false,
            activation_code: activation_code,
            email_hash: email_hash
          });
        }
      });
    }
  }

  async validateSubuserActivationLink(activation_code, email_hash) {
    return axios({
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      url: "/backend/account/preactivation",
      data: { activation_code: activation_code, email_hash: email_hash }
    }).then(res => res.data);
  }

  

  render() {
    const { getFieldDecorator } = this.props.form;
    const { validated } = this.state;

    return (
      <div
        className="gx-app-login-wrap"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundPosition: "right",

          backgroundRepeat: "no-repeat"
        }}
      >
        {validated !== null && validated === true ? (
          <div className="gx-app-login-container d-flex justify-content-center">
            {this.state.loader ? (
              <div>
                <Progress />
              </div>
            ) : null}
            <div className="gx-app-login-main-content">
              <div className="gx-app-logo-content">
                <div className="gx-app-logo-content-bg"></div>
                <div className="gx-app-logo-wid">
                  <h1>
                    <IntlMessages id="app.userAuth.activateAccount" />
                  </h1>
                  <p>Activate Your Account</p>
                  <br />
                  <div>
                    <span style={{ overflowWrap: "break-word" }}>
                      {this.state.email}
                    </span>
                  </div>
                </div>
                <div className="gx-app-logo">
                  <img
                    alt={getWhiteLabelPartnerName()}
                    src={getWhiteLabelLogoUrl()}
                  />
                </div>
              </div>

              <div className="gx-app-login-content">
                {this.state.showMessage === false ? (
                  <Form
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                    className="gx-signup-form gx-form-row0"
                  >
                    <FormItem>
                      {getFieldDecorator("first_name", {
                        rules: [
                          {
                            required: true,
                            message: "Please input your First name!",
                            setFieldsValue: this.state.first_name
                          }
                        ]
                      })(
                        <Input
                          placeholder="First Name"
                          onChange={this.handleInputChange}
                        />
                      )}
                    </FormItem>
                    <FormItem>
                      {getFieldDecorator("last_name", {
                        rules: [
                          {
                            required: true,
                            message: "Please input your Last name!",
                            setFieldsValue: this.state.last_name
                          }
                        ]
                      })(
                        <Input
                          placeholder="Last Name"
                          onChange={this.handleInputChange}
                        />
                      )}
                    </FormItem>
                    <FormItem>
                      {getFieldDecorator("title", {
                        rules: [
                          {
                            required: true,
                            message: "Input the User title",
                            setFieldsValue: this.state.title
                          }
                        ]
                      })(
                        <Input
                          placeholder="Title"
                          onChange={this.handleInputChange}
                        />
                      )}
                    </FormItem>
                    <FormItem>
                      {getFieldDecorator("phone_number", {
                        rules: [
                          {
                            required: true,
                            message: "Input the User Phone Number",
                            setFieldsValue: this.state.phone_number
                          }
                        ]
                      })(
                        <Input
                          placeholder="Phone Number"
                          onChange={this.handleInputChange}
                        />
                      )}
                    </FormItem>
                    <FormItem>
                      {getFieldDecorator("password", {
                        rules: [
                          {
                            required: true,
                            message: "Please input your Password!",
                            setFieldsValue: this.state.password
                          },
                          {
                            validator: this.passwordValidator
                          }
                        ]
                      })(
                        <Input
                          className="pw"
                          type="text"
                          autoComplete="new-password"
                          placeholder="Password"
                          onChange={this.handleInputChange}
                        />
                      )}
                    </FormItem>

                    <FormItem>
                      {getFieldDecorator("confirm", {
                        rules: [
                          {
                            required: true,
                            message: "Please confirm your password!",
                            setFieldsValue: this.state.confirmPassword
                          },
                          {
                            validator: this.compareToFirstPassword
                          }
                        ]
                      })(
                        <Input
                          className="pw"
                          type="text"
                          placeholder="Retype Password"
                          onChange={this.handleInputChange}
                          onBlur={this.handleConfirmBlur}
                        />
                      )}
                    </FormItem>
                    <br />
                    <br />
                    <FormItem>
                      <Button
                        type="primary"
                        className="gx-mb-0"
                        htmlType="submit"
                      >
                        <IntlMessages id="app.userAuth.activateAccount" />
                      </Button>
                    </FormItem>
                  </Form>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    {this.state.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : validated !== null && validated === false ? (
          <div className="gx-login-content">
            {this.state.loader ? (
              <div>
                <Progress />
              </div>
            ) : null}
            <div className="gx-login-header">
              <img
                src={getWhiteLabelLogoUrl()}
                alt={getWhiteLabelPartnerName()}
                title={getWhiteLabelPartnerName()}
              />
            </div>
            <div className="gx-mb-4">
              <h2>Activation</h2>
            </div>
            <span>
              Activation URL could not be validated, Please contact{" "}
              <a href={`mailto:${getWhiteLabelSupportEmail()}`}>
                {getWhiteLabelSupportEmail()}{" "}
              </a>
            </span>{" "}
          </div>
        ) : null}
      </div>
    );
  }
}

const WrappedSubUserActivationForm = Form.create()(SubUserActivation);

export default WrappedSubUserActivationForm;
