import { SET_CLOUD_ACTION } from "constants/ActionTypes";

const init = null;

export default (state = init, action) => {
    if (action.type === SET_CLOUD_ACTION) {
        state = action.payload;
    }

    return state;
}