import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Button from "antd/es/button";
import Widget from "../components/Widget/index";
import { getWhiteLabelPartnerName, getWhiteLabelSupportEmail } from "util/whitelabel";
import deadline from "assets/images/deadline.png";
import { signout } from "util/functions";
import "styles/pages/freeTrialExpired.css";

class FreeTrialExpired extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        sessionStorage.clear();
        localStorage.clear();
        this.props.planIdClear();
        this.props.planDetailsClear();
        signout();
    }

    render() {
        return (
            <div className="free-trial-expired">
                <Widget>
                    <div className="free-trial-expired-title">
                        <img src={deadline} alt="calendar deadline" />
                        <h1>Free Trial Expired</h1>
                    </div>

                    <p className="free-trial-expired-highlighted-text-block">
                        Please contact our Customer Success team at <a href={`mailto:${getWhiteLabelSupportEmail()}?subject=${getWhiteLabelPartnerName()} Free Trial Expired - Purchase Paid Plan`}>{getWhiteLabelSupportEmail()}</a> to purchase a paid plan.
                        They will be happy to assist you with the payment process and any questions you may have.
                    </p>

                    <p className="free-trial-expired-text-block">
                        We hope you've been enjoying the features and benefits offered by {getWhiteLabelPartnerName()}. 
                        Your free trial has expired, but all <span className="free-trial-expired-ultrabold">your data is safe and secure</span>. 
                        You will be able to access your account again once you purchase a paid plan.
                    </p>

                    <p className="free-trial-expired-text-block">
                        We understand that managing payments can sometimes be a hassle, 
                        but rest assured that our team is here to help make the process as seamless as possible.
                    </p>

                    <Link to="/signin">
                        <Button type="default">
                            Go to Sign In Page
                        </Button>
                    </Link>
                </Widget>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        planIdClear: () => dispatch({ type: "PlanIdClear" }),
        planDetailsClear: () => dispatch({ type: "PlanDetailsClear" })
    };
};

export default connect(null, mapDispatchToProps)(FreeTrialExpired);
