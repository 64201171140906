import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router";
import Collapse from "antd/es/collapse";
import Skeleton from "antd/es/skeleton";
import LinuxGuide from "./LinuxGuide";
import MacOSGuide from "./MacOSGuide";
import WindowsGuide from "./WindowsGuide";
const { Panel } = Collapse;

class InstallGuide extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            unauthorized: false
        }
    }

    componentDidMount = () => {
        axios
            .get("/backend/general/deviceManagement/verifySession")
            .then(res => {
                if(res.data === "Unauthorized") { 
                    this.setState({ unauthorized: true });
                    return;
                }
                this.setState({ loading: false });
            })
    }

    render = () => {
        const { loading, unauthorized } = this.state;

        if(unauthorized) return <Redirect from="/deviceagent/install" to="/deviceagent/install/validate" />;
        return (
            <div style={{ paddingBottom: "40px" }}>
                <div className="guide-container">
                    <h1 className="top-page-title">Device Agent Installation and Setup Guide</h1>
                    <hr/>
                    <h2>Overview</h2>
                    
                    <p>
                        The device agent will send information about your device to your employer so they can ensure the company satisfies compliance standards.
                    </p>
                    <p>
                        To do this, a product called osquery is installed on your device with a specific configuration that authorizes your device to send updates to your employer.  
                        You may install the device agent on each applicable device.
                    </p>
                    <p>
                        The configuration is directly tied to the invitation link that brought you here.  
                        <strong> Please be sure that you are using the link in the most recent invitation. </strong>
                        Otherwise, it's possible that the configuration you're using won't be authorized to send updates.
                    </p>
                    <hr/>
                    <h2>Installation</h2>
                    
                    <div>
                        <Collapse accordion>
                            <Panel
                                header={<span><i className="fab fa-windows"></i> Windows</span>} 
                                key="1"
                            >
                                <Skeleton loading={loading} active>
                                    <WindowsGuide />
                                </Skeleton>
                            </Panel>
                            <Panel 
                                header={<span><i className="fab fa-apple"></i> Mac OS</span>}
                                key="2"
                            >
                                <Skeleton loading={loading} active>
                                    <MacOSGuide />
                                </Skeleton>
                            </Panel>
                            {/* Linux guide should be commented out until we know if it will be supported at feature launch */}
                            {/* <TabPane 
                                tab={<span><i className="fab fa-linux"></i> Linux</span>} 
                                key="3"
                            >
                                <Skeleton loading={loading} active>
                                    <LinuxGuide />
                                </Skeleton>
                            </TabPane> */}
                        </Collapse>
                    </div>
                </div>
            </div>
        );
    }
}

export default InstallGuide;