import CallStorage from "./CallStorage";
import SessionStorage from "./SessionStorage";
import { keyConfig } from "./keyConfig";

// exports a call storage class for generic use

// if browser supports session storage, use it for call storage
// if no supported storage is found, call storage will not store values and always get a "new" value
// could add other types of storage here (like browser cookies)
let storage = null;
if (typeof(Storage) !== "undefined") {
    storage = new SessionStorage("callStorage-");
}

export const standardCallStorage = new CallStorage(
    keyConfig,
    storage,
    storage ? true : false
)