export default class SessionStorage {
    constructor(prefix="") {
        this.prefix = prefix;
    }

    get = (key) => {
        const value = sessionStorage.getItem(
            this._keyBuilder(key)
        );
        let parsedValue = value;
        if (typeof parsedValue === "string") {
            if (parsedValue.charAt(0) === "{") {
                parsedValue = JSON.parse(parsedValue)
            }
        }

        return parsedValue;
    }

    put = (key, value) => {
        let safeValue = value;
        if (typeof safeValue === "object") {
            safeValue = JSON.stringify(safeValue);
        }
        sessionStorage.setItem(
            this._keyBuilder(key),
            safeValue
        );
    }

    delete = (key) => {
        sessionStorage.removeItem(
            this._keyBuilder(key)
        );
    }

    _keyBuilder = (key) => {
        return this.prefix + key;
    }
}