const init = { URL: null };

const lastRequest = (state = init, action) => {
    switch (action.type) {
        case 'LAST_REQUEST':
            return { URL: action.payload }
        default: 
            return state;
    }
}

export default lastRequest;