import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  SIGNUP_USER,
  SUPPORT_SIGNIN_USER
} from "constants/ActionTypes";
import {
  showAuthMessage,
  userSignInSuccess,
  userSignUpSuccess
} from "../../appRedux/actions/Auth";
import {
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userGoogleSignInSuccess,
  userTwitterSignInSuccess
} from "../actions/Auth";
import axios from "axios";
import apiClientOC from "icfrontendapiclient";
import baseOC from "icfrontendbase";

const bizApiClient = apiClientOC.get("BizApiClient");
bizApiClient.registerEndpoint("signin", "post", "/auth/signin");
const sharedStorage = baseOC.get("SharedStorage");


const signInUserWithEmailPasswordAPI = async (email, password) => {
  localStorage.clear();
  
  // attempt sign in with credentials
  let loginDetails;
  let account_id, user_id;
  try {
    // this is temporarily using the request class directly to sign in
    // later we should use the login product component
    let resArr = await Promise.all([
      axios.post("/backend/account/loginSetProfile", { email, password }),
      bizApiClient.signin({ email_address: email, password })
    ]);
    const loginDetailsRes = resArr[0];
    const loginDetailslibRes = resArr[1];

    if (loginDetailslibRes?.is_error) {
      throw new Error("Error while signing in")
    }

    loginDetails = loginDetailsRes.data;
    sharedStorage.set("auth_is_signed_in", true);
    sharedStorage.set("auth_primary_role", loginDetails.primary_role);

    if (loginDetails.primary_role === "auditor") {
      window.location.replace("/auditor/login/redirect");
    }

    account_id = loginDetails.account_id;
    user_id = loginDetails.user_id;
  } catch(error) {
    return { user_id: null, account_id: null };
  }

  // get cloud account list
  try {
    const cloudAccountRes = await axios.get("/backend/account/cloudAccounts");
    const cloudAccountList = cloudAccountRes.data;

    // if no cloud accounts, then show the add cloud account page (instead of dashboard)
    if (cloudAccountList !== null && cloudAccountList.length <= 0) {
      sessionStorage.setItem("cloudAccountNeeded", true);
    } else {
      sessionStorage.setItem("cloudAccountNeeded", false);
    }
  } catch(error) {
    message("Error while retrieving Cloud Accounts, please try again");
  }

  return { user_id: user_id, account_id: account_id };
};

function* createUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signUpUser = yield call(
      createUserWithEmailPasswordRequest,
      email,
      password
    );
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      //sessionStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userSignUpSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGoogle() {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      sessionStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userGoogleSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithFacebook() {
  try {
    const signUpUser = yield call(signInUserWithFacebookRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      sessionStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userFacebookSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}
async function validatePartnerLogin() {
  return  axios({
    method: "get",
    headers: {
      "content-type": "application/json"
    },
    url: "/backend/partner/accountWhiteLabel"
  }).then(res => res.data);
}
function* signInUserWithGithub() {
  try {
    const signUpUser = yield call(signInUserWithGithubRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      sessionStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userGithubSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithTwitter() {
  try {
    const signUpUser = yield call(signInUserWithTwitterRequest);
    if (signUpUser.message) {
      if (signUpUser.message.length > 100) {
        yield put(showAuthMessage("Your request has been canceled."));
      } else {
        yield put(showAuthMessage(signUpUser.message));
      }
    } else {
      sessionStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userTwitterSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  const { email, password, partner } = payload;

  try {
    const signInUser = yield call(
      signInUserWithEmailPasswordAPI,
      email,
      password
    );

    if (signInUser !== null && signInUser !== undefined) {
      var result = yield call(validatePartnerLogin);
      // if at the partner login page
      if (partner && typeof partner !== "undefined" && partner === true) {
        // make a call here to check if the user is a partner
        // if yes, go ahead with login. If not direct him/her to signin
        // its a partner so login successful
        if (handlePartnerSignInUserWithEmailPassword(result, "/partnersignin")) {
          yield put(userSignInSuccess(signInUser.user_id));          
        } 
        else {
          yield put(
            showAuthMessage(
              "Not able to sign in the user, please make sure you are using the correct sign in page. You are at Partner Sign In page."
            )
          );
        }
        
      }
      // else not at the partner login page 
      else {
        // if no white labels then it is not a partner user
        if (result && result.whitelabels && result.whitelabels.length <= 0) {
          sessionStorage.removeItem("logoUrl");
          sessionStorage.setItem("user_id", "A09345G1234");
          yield put(userSignInSuccess(signInUser.user_id));
          sharedStorage.get("whitelabel").then(curWhitelabel => {
            // if there is not a domain whitelabel, then set the default whitelabel to sharedstorage
            if (curWhitelabel.logo_url === "") {
                sharedStorage.set("whitelabel", {
                    name: nonPersistentStorageObject.app_white_label.partner_name,
                    copyright_name: nonPersistentStorageObject.app_white_label.copyright_name,
                    safe_name: nonPersistentStorageObject.app_white_label.safe_partner_name,
                    logo_url: nonPersistentStorageObject.app_white_label.logo_url,
                    customer_support_contact: {
                        email: nonPersistentStorageObject.app_white_label.support_email,
                    },
                    feature_toggle: {
                        assessment_check_remediation_link: true
                    }
                })
            }
        });
        }
        // else try to login as partner
        else if (handlePartnerSignInUserWithEmailPassword(result, "/signin")) {
          yield put(userSignInSuccess(signInUser.user_id));
        } 
        else {
          yield put(
            showAuthMessage(
              "Not able to authenticate the user, please check your Email and Password"
            )
          );
        }
      }
    }
    else {
      yield put(
        showAuthMessage(
          "Not able to authenticate the user, please check your Email and Password"
        )
      );
    }
  } 
  catch (error) {
      yield put(
        showAuthMessage(
          "Not able to authenticate the user, please check your Email and Password"
        )
      );
  }
}

function handlePartnerSignInUserWithEmailPassword(result, loginPathUsed) {
  if (
    result &&
    result !== null &&
    typeof result !== "undefined" &&
    result.whitelabels &&
    result.whitelabels.length > 0
  ) {
    sessionStorage.setItem("logoUrl", result.whitelabels[0].whitelabel.logo_url);
    sessionStorage.setItem("user_id", "A09345G1234");
    sessionStorage.setItem("loginPathUsed", loginPathUsed);
    sharedStorage.set("whitelabel", {
      name: "",
      copyright_name: "",
      safe_name: "",
      logo_url: result.whitelabels[0].whitelabel.logo_url,
      customer_support_contact: {
        email: nonPersistentStorageObject.app_white_label.support_email,
      },
      feature_toggle: {
        assessment_check_remediation_link: false
      }
    })
    return true;
  } 
  else return false;
}

function* supportUserSignInFunc({ payload }) {
  const { user_id, account_id, support_id } = payload;
  
  try {
    if (user_id  && account_id && support_id) {
      var result = yield call(validatePartnerLogin);
   
        if (
          result &&
          result.whitelabels &&
          result.whitelabels.length > 0
        ) {
            sessionStorage.setItem(
            "logoUrl",
            result.whitelabels[0].whitelabel.logo_url
          );
          sessionStorage.setItem("user_id", "A09345G1234");
          yield put(userSignInSuccess(signInUser.user_id));
          window.location.pathname = "/";
         } 
       else 
        if (result && result.whitelabels && result.whitelabels.length <= 0) {
          sessionStorage.removeItem("logoUrl");
          sessionStorage.setItem("user_id", "A09345G1234");
          yield put(userSignInSuccess("A09345G1234"));
          window.location.pathname = "/";
        }
     
      
    } else {
      yield put(
        showAuthMessage(
          "Not able to sign in the user, please check your Payload"
        )
      );
    }
  } catch (error) {
    yield put(
      showAuthMessage(
        "Not able to authenticate the user, please check your Payload"
      )
    );
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
  yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
  yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* supportUserSignIn() {
  yield takeEvery(SUPPORT_SIGNIN_USER, supportUserSignInFunc);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(createUserAccount),
    fork(signInWithGoogle),
    fork(signInWithFacebook),
    fork(signInWithTwitter),
    fork(signInWithGithub),
    fork(supportUserSignIn)
  ]);
}
