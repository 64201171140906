import baseOC from "icfrontendbase";
const sharedStorage = baseOC.get("SharedStorage");

// ##########################################################################
// 
// IMPORTANT for later with cloud selector
// to register a component for all portal paths...
//       activeRule: "/portal" 
// 
// ##########################################################################

const generateProductComponentConfig = async () => {
    const assessmentComplianceCodeList = await sharedStorage.get("cloud_assessment_complete_compliance_code_list");

    const RedirectComp = ({ href }) => {
        window.location.href = href;
        return null
    }

    const productComponentConfig = [
        // compliance summary route
        {
            unique_name: "compliance-summary",
            render_at_id: "portal-product-component",
            active_rule: "/portal/compliance/summary",
            componentType: "decisionWrapper",
            componentName: "CloudTypeDecisionWrapper",
            componentProps: {
                desiredInFocusCloudType: "azure",
                // (until aws moves to cloudscanss) if an aws cloud is selected, then show the legacy aws comp 
                incorrectCloudTypeComp: null, 
                // if the user doesn't have a cloud, then redirect them to the setup page
                noCloudTypeComp: <RedirectComp href="/account/cloudaccountsetup" />
            },
            childComponent: {
                componentType: "product",
                componentName: "ComplianceSummary",
                componentProps: {},
                childComponent: null
            }
        },

        // compliance detail routes
        ...assessmentComplianceCodeList.map(code => {
            return {
                unique_name: `comp-detail-${code}`,
                render_at_id: "portal-product-component",
                active_rule: `/portal/compliance/${code}`,
                componentType: "decisionWrapper",
                componentName: "CloudTypeDecisionWrapper",
                componentProps: {
                    desiredInFocusCloudType: "azure",
                    // (until aws moves to cloudscanss) if an aws cloud is selected, then show the legacy aws comp 
                    incorrectCloudTypeComp: null, 
                    // if the user doesn't have a cloud, then redirect them to the setup page
                    noCloudTypeComp: <RedirectComp href="/account/cloudaccountsetup" />
                },
                childComponent: {
                    componentType: "decisionWrapper",
                    componentName: "AddonFeatureAccessDecisionWrapper",
                    componentProps: {
                        featureKey: "assessment_compliance_code_list",
                        expectedValue: code, 
                        noAccessComp: {                            
                            _microservice_import: true,
                            componentType: "archAware",
                            componentName: "CompAssessLockedAddonPage",
                            componentProps: {
                                complianceCode: code
                            },
                            childComponent: null
                        }
                    },
                    childComponent: {
                        componentType: "product",
                        componentName: "CompDetail",
                        componentProps: {
                            complianceCode: code
                        },
                        childComponent: null
                    }
                }
            }
        })
    ]
    return productComponentConfig;
}

const getQiankunMicroApps = async () => {
    const productComponentConfig = await generateProductComponentConfig();
    const microApps = productComponentConfig.map(config => {
        return {
            name: config.unique_name,
            entry: "/icfrontendui/",
            container: `#${config.render_at_id}`,
            activeRule: config.active_rule,
            props: {
                componentType: config.componentType,
                componentName: config.componentName,
                componentProps: config.componentProps,
                childComponent: config.childComponent
            }
        }
    })
    
    return microApps;
}

export default getQiankunMicroApps;