import React from "react";
import SeverityMessage from "./SeverityMessage";

const WarningMessage = ({ title, children, ...rest }) => {
    return (
        <SeverityMessage
            title={title}
            severity="warning"
            {...rest}
        >
            {children}    
        </SeverityMessage>
    )
}

export default WarningMessage;